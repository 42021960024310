import React from 'react'
import { BarList, Card } from '@tremor/react'
import { api } from 'services/api'
import CustomButton from 'components/CustomButton'
import { Download } from 'lucide-react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

// eslint-disable-next-line space-before-function-paren
export default function CampaignCard({ campaign, listName, showPreviewDefault }) {
  const tabs = ['Summary', 'Details']
  const [selectedTab, setSelectedTab] = React.useState('Summary')
  const html = campaign?.unlayerConfig?.html
  const history = useHistory()
  const valueFormatter = (number) =>
    `${Intl.NumberFormat('us').format(number).toString()}`
  const [showPreview, setShowPreview] = React.useState(showPreviewDefault ?? false)
  const [loadingReportId, setLoadingReportId] = React.useState(null)
  const sendGridReport = campaign.sendGridReport
  const report = sendGridReport ?? {
    bounce_drops: 0,
    bounces: 0,
    clicks: 0,
    delivered: 0,
    invalid_emails: 0,
    opens: 0,
    requests: 0,
    spam_report_drops: 0,
    spam_reports: 0,
    unique_clicks: 0,
    unique_opens: 0,
    unsubscribes: 0
  }
  // Delivered
  // Opened
  // Clicked
  // Unsubscribed
  // Invalid Emails
  // Bounced
  const deviceId = localStorage.getItem('device_id')
  const content = [
    {
      name: 'Delivered',
      value: report?.delivered ?? 0
    },
    {
      name: 'Opened',
      value: report?.opens ?? 0
    },
    {
      name: 'Clicked',
      value: report?.clicks ?? 0
    },
    {
      name: 'Unsubscribed',
      value: report?.unsubscribes ?? 0
    },
    {
      name: 'Bounced',
      value: report?.bounces ?? 0
    },
    {
      name: 'Invalid Emails',
      value: report?.invalid_emails ?? 0
    }, {
      name: 'Spam Reports',
      value: report?.spam_reports ?? 0
    }
  ]

  const handleDownloadReport = async (campaignId) => {
    // @ts-ignore
    setLoadingReportId(campaignId)
    const response = await api.get(
      `/reports/email-campaigns/${campaignId}?deviceId=${deviceId}`,
      {},
      { responseType: 'blob' }
    )
    if (response.ok) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'campaign-report.xlsx')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      setLoadingReportId(null)
    } else {
      console.error('Error fetching file:', response.status)
    }
  }
  const userListId = typeof campaign?.userList === 'object' ? campaign?.userList?._id : ''
  const emailTemplate = {
    _id: typeof campaign?.emailTemplateId === 'object' ? campaign?.emailTemplateId?._id : '',
    name: typeof campaign?.emailTemplateId === 'object' ? campaign?.emailTemplateId?.name : ''
  }
  return <Card className={
    `flex flex-row max-h-[650px] px-0 ${showPreview ? 'col-span-2' : 'col-span-1'}`
  }
    onMouseEnter={() => {
      setShowPreview(true)
    }}
    onMouseLeave={() => {
      setShowPreview(false)
    }}
  >
    <div className='flex flex-col divide-y space-y-4 px-0 flex-1'>
      <div className='flex flex-col gap-y-2 px-3'>
        <div className='flex justify-between'>
          <div className='bg-[#65C4ED59]  rounded px-2 py-1 text-[16px] flex items-center gap-x-2'>
            <span className='w-3 h-3 bg-[#3B82F6] rounded-full'>
            </span>
            <div>
              Email
            </div>
          </div>
          <div className='flex justify-between items-center gap-x-1'>
            {campaign?.sendGridCampaignId && (
              <a href={`${history.location.pathname}/${campaign._id}`}>
                <CustomButton className='px-2 py-1'>Report</CustomButton>
              </a>
            )}
            {campaign?.sendGridCampaignId && (
              <CustomButton className='px-2 py-1'
                disabled={loadingReportId === campaign._id}
                handleClick={() => {
                  handleDownloadReport(campaign._id)
                }}
              >
                <Download size={16} />
                {loadingReportId === campaign._id ? 'Downloading...' : 'Download'}
              </CustomButton>
            )}
          </div>
        </div>
        <div className='font-bold text-lg'>{campaign.name}</div>
        <div className='flex'>
          <span className='w-fit'>
            {campaign.status === 'sent' && <div className='bg-green-400 text-white font-semibold px-2 py-1 rounded-full text-xs'>Sent</div>}
            {campaign.status === 'draft' && <div className='bg-yellow-400 text-white font-semibold px-2 py-1 rounded-full text-xs'>Draft</div>}
            {campaign.status === 'scheduled' && <div className='bg-gray-400 text-white font-semibold px-2 py-1 rounded-full text-xs'>Scheduled for {campaign.scheduleAt ? new Date(campaign.scheduleAt).toLocaleString() : ''}</div>}
            {campaign.status === 'sending' && <div className='bg-blue-400 text-white font-semibold px-2 py-1 rounded-full text-xs'>Sending</div>}
            {campaign.status === 'failed' && <div className='bg-red-400 text-white font-semibold px-2 py-1 rounded-full text-xs'>Failed</div>}
            {campaign.status === 'processing' && <div className='bg-blue-400 text-white font-semibold px-2 py-1 rounded-full text-xs'>Processing</div>}
          </span>
        </div>
        <div className='w-full flex flex-col gap-y-2'>
          <div className='flex justify-between w-full'>
            <div className='flex items-center gap-x-1'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.3859 10.8635L17.4294 14.3164L18.6146 19.4571C18.6773 19.7259 18.6594 20.0072 18.5632 20.2658C18.4669 20.5244 18.2965 20.7489 18.0734 20.9112C17.8502 21.0736 17.5841 21.1665 17.3084 21.1784C17.0327 21.1904 16.7596 21.1208 16.5232 20.9783L12.0395 18.2588L7.56551 20.9783C7.32915 21.1208 7.05605 21.1904 6.78034 21.1784C6.50463 21.1665 6.23856 21.0736 6.01539 20.9112C5.79221 20.7489 5.62184 20.5244 5.52557 20.2658C5.4293 20.0072 5.4114 19.7259 5.47412 19.4571L6.65755 14.3217L2.70022 10.8635C2.49091 10.683 2.33956 10.4447 2.26514 10.1785C2.19073 9.91228 2.19656 9.63004 2.28191 9.36715C2.36727 9.10425 2.52833 8.87241 2.74492 8.7007C2.96151 8.52898 3.22397 8.42503 3.4994 8.40189L8.7156 7.9501L10.7517 3.09357C10.8581 2.83874 11.0374 2.62107 11.2672 2.46796C11.497 2.31485 11.7669 2.23315 12.0431 2.23315C12.3192 2.23315 12.5891 2.31485 12.8189 2.46796C13.0487 2.62107 13.2281 2.83874 13.3344 3.09357L15.3767 7.9501L20.5911 8.40189C20.8665 8.42503 21.129 8.52898 21.3456 8.7007C21.5622 8.87241 21.7232 9.10425 21.8086 9.36715C21.8939 9.63004 21.8998 9.91228 21.8254 10.1785C21.7509 10.4447 21.5996 10.683 21.3903 10.8635H21.3859Z" fill="#2E2B2E" fillOpacity="0.5" />
              </svg>
              <div className='text-gray-500'>Date Sent</div>
            </div>
            <div className='bg-[#F0EEE9] px-2 py-1 rounded'>
              {new Date(campaign.createdAt).toLocaleString()}
            </div>
          </div>
          <div className='flex justify-between w-full'>
            <div className='flex items-center gap-x-1'>
              <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0724 17.8886C19.0108 17.9953 18.9222 18.0839 18.8154 18.1455C18.7087 18.2071 18.5877 18.2395 18.4644 18.2395H1.62101C1.49788 18.2394 1.37695 18.2069 1.27036 18.1452C1.16377 18.0836 1.07528 17.995 1.01377 17.8883C0.952255 17.7816 0.91989 17.6607 0.919922 17.5375C0.919954 17.4144 0.95238 17.2934 1.01395 17.1868C2.35002 14.877 4.40895 13.2207 6.81177 12.4356C5.62323 11.728 4.6998 10.6499 4.18331 9.36671C3.66681 8.08356 3.5858 6.66633 3.95272 5.33268C4.31964 3.99903 5.1142 2.82269 6.21438 1.98432C7.31456 1.14594 8.65952 0.691895 10.0427 0.691895C11.4259 0.691895 12.7709 1.14594 13.8711 1.98432C14.9713 2.82269 15.7658 3.99903 16.1327 5.33268C16.4997 6.66633 16.4186 8.08356 15.9022 9.36671C15.3857 10.6499 14.4622 11.728 13.2737 12.4356C15.6765 13.2207 17.7354 14.877 19.0715 17.1868C19.1332 17.2934 19.1658 17.4144 19.166 17.5376C19.1661 17.6608 19.1339 17.7819 19.0724 17.8886Z" fill="#2E2B2E" fillOpacity="0.5" />
              </svg>
              <div className='text-gray-500'>List</div>
            </div>
            {!userListId && (
              <div className='bg-[#D6FABB] px-2 py-1 rounded'>
                {listName ?? 'N/A'}
              </div>
            )}
            {userListId &&
              <a href={`/admin/lists/${userListId}`} style={{
                textDecoration: 'none',
                color: 'black'
              }}>
                <div className='bg-[#D6FABB] px-2 py-1 rounded'>
                  {listName}
                </div>
              </a>}
          </div>
          <div className='flex justify-between w-full'>
            <div className='flex items-center gap-x-1'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#2E2B2E" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-layout-panel-top"><rect width="18" height="7" x="3" y="3" rx="1" /><rect width="7" height="7" x="3" y="14" rx="1" /><rect width="7" height="7" x="14" y="14" rx="1" /></svg>
              <div className='text-gray-500'>Email Template</div>
            </div>
            {!userListId && (
              <div className='bg-[#D6FABB] px-2 py-1 rounded'>
                {emailTemplate.name ?? 'N/A'}
              </div>
            )}
            {userListId &&
              <a href={`/admin/email-builder/edit/${emailTemplate._id}`}
                style={{
                  textDecoration: 'none',
                  color: 'black'
                }}
              >
                <div className='bg-[#D6FABB] px-2 py-1 rounded'>
                  {emailTemplate.name}
                </div>
              </a>}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-y-2 px-3'>
        <div className='flex mt-2'>
          {tabs.map((tab) => {
            return <button
              type='button'
              onClick={() => {
                setSelectedTab(tab)
              }}
              className={`h-full uppercase py-2 px-4 text-sm font-medium overflow-hidden font-sans ${selectedTab === tab
                ? 'border-b-softBlack border-[1px] border-t-0 border-l-0 border-r-0 text-softBlack'
                : 'bg-transparent text-softBlack'
                }`}
            >
              {tab}
            </button>
          })
          }
        </div>
        {selectedTab === 'Summary' &&
          <div className='flex flex-col gap-y-2'>
            <BarList data={content} valueFormatter={valueFormatter} className='mt-2' />
          </div>
        }
      </div>
    </div>
    {showPreview &&
      <div className='left-[100%] flex-1 border-l border-border px-2 border-collapse h-full overflow-y-auto'>
        <div className='border-2 border-black h-full overflow-y-auto'>
          <div dangerouslySetInnerHTML={{ __html: html }} className='h-[100%] overflow-y-auto' />
        </div>
      </div>
    }
  </Card>
}
