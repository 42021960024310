// @ts-nocheck
// import CategoryButton from 'components/AdminSideBar/CategoryButton'
import React, { useEffect, useState, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { PageHeader, TFilledPlusIcon } from 'components'
import { ADMIN_CREATE_EVENT_PAGE } from 'routes'
import { useHistory, useParams } from 'react-router-dom'
import { getEvents, getImage, getEventsAttendees, getEventStats, updateAttendees } from 'store/actions/eventsActions'
import AddAttendeeModal from './components/AddAttendeeModal'
import CheckinModal from './components/checkinModal'
import CustomButton from 'components/CustomButton'
import { Select } from 'components/ShadcnComponents/select'
import { Badge } from 'components/ShadcnComponents/badge'
import { Button } from 'components/ShadcnComponents/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ShadcnComponents/table'
import { Mail, MessageSquare, Pen, UserPlus, Upload } from 'lucide-react'
import AddOrEditBulkContact from './components/AttendeeBulkUpload'

const RSVP_STATUSES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Pending', label: 'Pending' }
]

const EventDetailPage = (props) => {
  const appProject = useSelector(state => state.appReducer.appProject)
  const history = useHistory()
  const { eventId } = useParams()
  const [event, setEvent] = useState()
  const [heroImage, setHeroImage] = useState()
  const [coverImage, setCoverImage] = useState()
  const [date, setDate] = useState()
  const [isShowCreateAttendeeModal, setIsShowCreateAttendeeModal] = useState(false)
  const [attendees, setAttendees] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredAttendees, setFilteredAttendees] = useState([])
  const [stats, setStats] = useState(null)
  const [isShowCheckinModal, setIsShowCheckinModal] = useState(false)
  const [selectedAttendee, setSelectedAttendee] = useState(null)
  const [isOpenBulkImportModal, setIsOpenBulkImportModal] = useState(false)

  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (title?.includes('Buyer')) {
      return {
        text: '#03543F',
        bg: '#DEF7EC'
      }
    }
    if (title?.includes('LeadBroker')) {
      return {
        text: '#5521B5',
        bg: '#EDEBFE'
      }
    }
  }, [])

  const LeadStatusBadge = ({ title }) => {
    const badgeColor = leadStatusBadgeColorFinder(title)
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text
    }
    if (badgeColor) {
      return (
        <div
          className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
          style={badgeStyle}
        >
          <div className='text-center text-xs font-normal'>{title}</div>
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    if (eventId) {
      refreshData()
    }
  }, [eventId])

  const refreshData = () => {
    getEvents(eventId).then((data) => {
      setEvent(data)
    })
    getEventsAttendees(eventId).then((adata) => {
      setAttendees(adata)
    })
    getEventStats(eventId).then((sdata) => {
      setStats(sdata)
    })
  }

  const filterData = () => {
    if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
      setFilteredAttendees(attendees)
    }
    const filtered = attendees.filter(({ user }) => {
      const name = (user?.fullName || '').toLowerCase()
      const email = (user?.email || '').toLowerCase()
      const phone = String(user?.userType === 'Buyer' ? user?.buyerData?.phoneNumber : user?.leadBrokerData?.phoneNumber || '')
      return name.includes(searchQuery.toLowerCase()) || email.includes(searchQuery.toLowerCase()) || phone.toLowerCase().includes(searchQuery.toLowerCase())
    })
    setFilteredAttendees(filtered)
  }

  useEffect(() => {
    filterData()
  }, [searchQuery, attendees])

  const updateStatus = (attendee, status) => {
    updateAttendees({ _id: eventId, attendeeId: attendee._id, ...attendee, status }, 'status').then(() => {
      refreshData()
    })
  }

  useEffect(() => {
    if (event) {
      var startTime = new Date(event.startDate)
      var endTime = new Date(event.endDate)
      var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      setDate(formattedStartDate + ' - ' + formattedEndDate)
      if (event.heroImage) {
        getImage(event.heroImage).then((res) => setHeroImage(res)).catch(() => setHeroImage())
      }
      if (event.coverImage) {
        getImage(event.coverImage).then((res) => setCoverImage(res)).catch(() => setCoverImage())
      }
    }
  }, [event])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <CustomButton
        className='w-fit m-auto'
        handleClick={() => history.push(ADMIN_CREATE_EVENT_PAGE)}
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          <TFilledPlusIcon className='h-6 w-6' />
          Add New Events
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader title='Events' rightContent={<RightContent />} />

      {!event
        ? <div className='w-full h-[calc(100%-100px)] flex justify-center items-center'>
          <p className='col-span-12 text-center font-medium text-xl'>Event detail is loading...</p>
        </div>
        : <div className='flex flex-col gap-6'>
          <div className='relative z-[1] space-y-4 bg-no-repeat bg-cover' style={{ backgroundImage: `url(${coverImage?.url || '/event-detail-hero.png'})` }}>
            <div className='bg-black/60 z-[2] absolute w-full h-full top-0 left-0' />
            <div className='relative z-[3] w-full container flex items-end gap-6 py-6'>
              <img
                src={heroImage?.url || '/event-detail-image.png'}
                alt={`${event.name} hero image`}
                className='object-cover w-[250px] h-[250px] rounded-lg'
              />
              <div className='flex-1 flex flex-col gap-4'>
                <div className='w-full flex items-center justify-between gap-4'>
                  <h1 className='text-3xl text-white font-bold mb-0'>{event.name}</h1>
                  <Button variant='secondary' onClick={() => history.push(`/admin/events/edit/${eventId}`)}>
                    <Pen className='w-4 h-4 mr-2' />
                    Edit Event
                  </Button>
                </div>
                <div className='flex flex-wrap gap-2 mt-2 text-sm text-muted-foreground'>
                  <Badge variant='white' className='px-4 py-1'>
                    {date}
                  </Badge>
                  <Badge variant='white' className='px-4 py-1'>
                    {event.startTime + ' - ' + event.endTime}
                  </Badge>
                  <Badge variant='white' className='px-4 py-1'>
                    {event.locationAddress}
                  </Badge>
                </div>
                <div className='flex flex-wrap gap-4'>
                  <Badge variant='black' className='px-4 py-2.5 text-base'>
                    {stats?.totalAttendees} Invited
                  </Badge>
                  <Badge variant='white' className='px-4 py-2.5 text-base'>
                  {stats?.rsvpYes} RSVP Yes
                  </Badge>
                  <Badge variant='white' className='px-4 py-2.5 text-base'>
                    {stats?.checkInCompleted} Checked In
                  </Badge>
                </div>
              </div>
            </div>
          </div>

          <div className='container space-y-4 border rounded-lg p-4'>
            <div className='flex flex-wrap items-center justify-between gap-4'>
              <h2 className='text-xl font-semibold'>Attendees</h2>
              <div className='flex flex-wrap items-center gap-2'>
                <Button variant='outline'>
                  <Mail className='w-4 h-4 mr-2' />
                  Email Attendees
                </Button>
                <Button variant='outline'>
                  <MessageSquare className='w-4 h-4 mr-2' />
                  SMS Attendees
                </Button>
                <Button variant='outline' onClick={() => setIsOpenBulkImportModal(true)}>
                  <Upload className='w-4 h-4 mr-2' />
                  import Attendee
                </Button>
                <Button onClick={() => setIsShowCreateAttendeeModal(true)}>
                  <UserPlus className='w-4 h-4 mr-2' />
                  Add Attendee
                </Button>
              </div>
            </div>
            <div className="py-2">
              <input
                type="text"
                placeholder="Search attendees..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  // filterData(attendees)
                }}
                className="w-[300px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone Number</TableHead>
                  <TableHead>Contact Type</TableHead>
                  <TableHead>RSVP Status</TableHead>
                  <TableHead>Check In</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredAttendees?.map((attendee, index) => (
                  <TableRow key={index}>
                    <TableCell>{attendee?.user?.fullName}</TableCell>
                    <TableCell>{attendee?.user?.email}</TableCell>
                    <TableCell>{attendee?.user?.userType === 'Buyer' ? attendee?.user?.buyerData?.phoneNumber : attendee?.user?.leadBrokerData?.phoneNumber }</TableCell>
                    <TableCell>
                      <LeadStatusBadge title={attendee?.user?.userType}/>
                    </TableCell>
                    <TableCell>
                      <Select
                        value={attendee?.status}
                        onChange={(newValue) => {
                          updateStatus(attendee, newValue)
                        }
                        }
                        options={RSVP_STATUSES}
                        placeholder="Select status"
                        className="w-8"
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant={attendee.checkIn === 'Pending' ? 'default' : 'outline'}
                        size="sm"
                        onClick={() => {
                          setSelectedAttendee(attendee)
                          setIsShowCheckinModal(true)
                        }
                        }
                        disabled={attendee.checkIn === 'Completed'}
                      >
                        {attendee.checkIn === 'Pending' ? ' Check In ' : 'Checked In'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      }
      {isOpenBulkImportModal && (
        <AddOrEditBulkContact
          show={isOpenBulkImportModal}
          setShow={setIsOpenBulkImportModal}
          onSuccess={() => refreshData()}
          eventId={eventId}
          project={appProject}/>
      )}
      <AddAttendeeModal
        show={isShowCreateAttendeeModal}
        onClose={() => setIsShowCreateAttendeeModal(false)}
        eventId={eventId}
        refreshData={refreshData}
        setIsShowCreateAttendeeModal={setIsShowCreateAttendeeModal}
      />
      <CheckinModal
        show={isShowCheckinModal}
        onClose={() => setIsShowCheckinModal(false)}
        selectedAttendee={selectedAttendee}
        refreshData={refreshData}
        setIsShowCheckinModal={setIsShowCheckinModal}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailPage)

// Old UI
//    <>
//       <div className='col-span-12 grid grid-cols-12 gap-x-4 px-10 pb-4 '>
//         <div className='col-span-6'>
//           <img
//             src={heroImage?.url || '/event-detail-image.png'}
//             alt={`${event.name} hero image`}
//             className='w-full object-contain h-full lg:h-sm lg:max-h-sm'
//           />
//         </div>
//         <div className='col-span-6 flex flex-col'>
//           <div className='flex justify-between items-center'>
//             <div>
//               <div className='font-bold text-[28px]'>{event.name}</div>
//             </div>
//             <div>
//               <CustomButton handleClick={() => setIsShowCreateAttendeeModal(true)}>
//                 <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
//                   <img src='/person-grp.png' className='invert w-6' />
//                   <div className='font-bold italic text-[14px]'>
//                     Add Attendees
//                   </div>
//                 </span>
//               </CustomButton>
//             </div>
//           </div>
//           <div className='flex justify-between mt-4'>
//             <div className='flex flex-col italic text-[18px]'>
//               <div>{date}</div>
//               <div>{event.startTime + ' - ' + event.endTime}</div>
//             </div>
//             <div>
//               <CustomButton variant='outline' handleClick={() => history.push(`/admin/events/edit/${eventId}`)}>
//                 <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
//                   <img src='/edit-pen-icon.png' />
//                   <div className='text-[14px] font-normal'>Edit Event</div>
//                 </span>
//               </CustomButton>
//             </div>
//           </div>
//           <div className='flex items-center gap-2 mt-4'>
//             <img src='/location-black.png' />
//             <div className='text-[18px]'>{event.locationAddress}</div>
//           </div>
//           <div className='grid col-span-12 grid-cols-12 gap-4 mt-4'>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 INVITED
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>
//                 300
//               </div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='col-span-12 rounded-b-[4px] grid grid-cols-12 gap-4 px-10 pt-6 pb-6 bg-[#F2F2F1]'>
//         <div className='w-full col-span-12 container mx-auto flex justify-between'>
//           {EVENT_DETAIL_BAR.map((category, index) => (
//             <CategoryButton
//               key={category.title}
//               category={category}
//               selected={selectedCategory === category?.value}
//               setSelectedCategory={setSelectedCategory}
//             >
//               <div className='py-2 border-[1px] border-black font-light italic text-[16px]'>
//                 {category.title}
//               </div>
//             </CategoryButton>
//           ))}
//         </div>
//         <div className='col-span-12 grid grid-cols-12 gap-4'>
//           <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Omar Bator
//             </div>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Buyer
//             </div>
//             <div className='  col-span-3 items-center flex w-full  gap-x-6 col-start-8 lg:col-start-9'>
//               <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
//                 RSVP YES
//               </button>
//               <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
//                 PENDING
//               </button>
//             </div>
//             <button className='col-span-1  col-start-12'>
//               {/*   <img src='/three-dot-dark.png' className='h-6 w-6 mr-8' /> */}
//             </button>
//           </div>
//           <div className='w-full h-[1px] bg-black col-span-12'></div>
//           <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Alena Donin
//             </div>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Broker
//             </div>
//             <div className='col-span-3 items-center justify-between flex w-full   gap-x-6 col-start-8 lg:col-start-9'>
//               <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
//                 RSVP YES
//               </button>
//               <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
//                 PENDING
//               </button>
//             </div>
//             <button className='col-span-1 flex  lg:justify-center col-start-12'>
//               <img src='/three-dot-dark.png' className='h-6 w-6 md:mr-8' />
//             </button>
//           </div>
//           <div className='w-full h-[1px] bg-black col-span-12'></div>
//           <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Lindsey Calzoni
//             </div>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Sales Rep
//             </div>
//             <div className='  col-span-3 items-center flex w-full  gap-x-6 col-start-8 lg:col-start-9'>
//               <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
//                 RSVP YES
//               </button>
//               <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
//                 PENDING
//               </button>
//             </div>
//             <button className='col-span-1  col-start-12'>
//               {/*   <img src='/three-dot-dark.png' className='h-6 w-6 mr-8' /> */}
//             </button>
//           </div>
//           <div className='w-full h-[1px] bg-black col-span-12'></div>
//         </div>
//       </div>
//     </>
