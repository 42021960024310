/* eslint-disable */
// @ts-nocheck
import CustomButton from "pages/contacts/CustomButton"
import React, { useState } from "react"
import { Modal } from "@material-ui/core"
import { X } from "lucide-react"
import clsx from "clsx"
import { isMobile } from "react-device-detect"
import { ReactComponent as SingleContact } from "assets/icons/singleContact.svg"

const CardRadio = ({ id, title, Icon, onClick, selected }) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={clsx(
        "group p-4 md:p-0 cursor-pointer flex flex-col w-full items-center justify-center h-[120px] rounded overflow-hidden border",
        selected ? "bg-zinc-100" : "bg-white"
      )}
    >
      {Icon && (
        <span className="px-1.5 text-softBlack flex items-center justify-center">
          {Icon}
        </span>
      )}
      <span className="mt-4 font-light text-base text-softBlack italic">
        {title}
      </span>
    </div>
  )
}

const UnitPurchaseModal = ({
  show,
  setShow,
  onMethodSelected = (selected) => {},
}) => {
  const [selectedRadio, setSelectedRadio] = useState(null)

  const handleClose = () => {
    setSelectedRadio(null)
    setShow(false)
  }
  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="Contact Edit Method Selection Modal"
        aria-describedby="Contact Edit Method Selection Modal description"
      >
        <div
          className="font-openSans relative bg-white p-5 md:p-7 lg:p-10 text-center flex flex-col items-center justify-center text-softBlack rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden"
          style={{
            width: isMobile ? "95%" : 1015,
            maxHeight: isMobile ? "95vh" : 400,
          }}
        >
          <button
            className="absolute right-5 md:right-7 lg:right-10 top-5 md:top-7 lg:top-10"
            onClick={handleClose}
          >
            <X />
          </button>
          <h1 className="font-bold text-xl text-softBlack">New Contact</h1>
          <p className="font-normal text-base text-softBlack mt-2 md:mt-8">
            Choose the method you want to use to create the buyer
          </p>
          <div className="w-full flex flex-col md:flex-row md:mx-10 mt-2 md:mt-8 gap-4 md:gap-x-6">
            <CardRadio
              id="quick-form"
              key="quick-form"
              title="Quick Form"
              selected={selectedRadio === "quick-form"}
              onClick={() => {
                setSelectedRadio("quick-form")
              }}
              Icon={<SingleContact />}
            />
            <CardRadio
              id="wizard"
              key="wizard"
              title="Wizard"
              selected={selectedRadio === "wizard"}
              onClick={() => {
                setSelectedRadio("wizard")
              }}
              Icon={<SingleContact />}
            />
          </div>
          <div className="flex mt-8 justify-center">
            <CustomButton
              className="w-fit mx-auto"
              handleClick={() => {
                handleClose()
                onMethodSelected(selectedRadio)
              }}
            >
              <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">
                Continue
              </span>
            </CustomButton>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UnitPurchaseModal
