/* eslint-disabled */
// @ts-nocheck
import React, { useState } from 'react'
import clsx from 'clsx'

const Tabs = ({ children, defaultValue }) => {
  const [activeTab, setActiveTab] = useState(defaultValue)

  const tabs = React.Children.toArray(children).filter((child) => child.type.displayName === 'TabsList')
  const content = React.Children.toArray(children).filter((child) => child.type.displayName === 'TabsContent')

  return (
    <div>
      {React.cloneElement(tabs[0], { activeTab, setActiveTab })}
      {content.map((child) =>
        React.cloneElement(child, { isActive: child.props.value === activeTab })
      )}
    </div>
  )
}

const TabsList = ({ children, className, activeTab, setActiveTab }) => (
  <div
    className={clsx(
      'inline-flex h-10 items-center justify-center rounded-md bg-white p-1 text-black',
      className
    )}
  >
    {React.Children.map(children, (child) =>
      React.cloneElement(child, { isActive: child.props.value === activeTab, setActiveTab })
    )}
  </div>
)

TabsList.displayName = 'TabsList'

const TabsTrigger = ({ children, className, value, isActive, setActiveTab, onClick }) => (
  <button
    onClick={() => {
      setActiveTab(value)
      onClick()
    }}
    className={clsx(
      'inline-flex items-center justify-center whitespace-nowrap rounded-full px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      isActive
        ? 'bg-black text-white'
        : 'data-[state=inactive]:text-black',
      className
    )}
  >
    {children}
  </button>
)

TabsTrigger.displayName = 'TabsTrigger'

const TabsContent = ({ children, className, isActive }) => (
  isActive && (
    <div
      className={clsx(
        'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        className
      )}
    >
      {children}
    </div>
  )
)

TabsContent.displayName = 'TabsContent'

export { Tabs, TabsList, TabsTrigger, TabsContent }
