// @ts-nocheck
import { PageHeader } from 'components'
import React, { useEffect, useState } from 'react'
import { EmailEditor } from 'react-email-editor'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CreateEmailCampaign from './CreateEmailCampaign'
import { createOrUpdateEmailTemplates, getEmailTemplates } from 'store/actions/emailTemplateActions'
import SendTestEmail from './SendTestEmail'
import { EyeIcon, SendHorizonal } from 'lucide-react'
import MergeFieldsAndTemplates from './MergeFieldsAndTemplates'
import EmailTemplatePreview from '../TemplatePreview'
import CustomButton from 'components/CustomButton'

const availableMergeFields = [
  {
    key: 'first_name',
    label: 'First Name',
    sample: 'John'
  },
  {
    key: 'last_name',
    label: 'Last Name',
    sample: 'Doe'
  }
]

const mergeTags = availableMergeFields.reduce((acc, field) => {
  acc[field.key] = {
    ...field,
    key: `{{${field.key}}}`
  }
  return acc
}, {})

const EditEmailTemplate = () => {
  const history = useHistory()
  const { templateId } = useParams()
  const [template, setTemplate] = useState()
  const [isSliderOpen, setIsSliderOpen] = useState(false)
  const [isEmailsPreviewOpen, setIsEmailsPreviewOpen] = useState(false)
  const [isSendTestEmailOpen, setIsSendTestEmailOpen] = useState(false)
  const [emailEditor, setEmailEditor] = useState()
  useEffect(() => {
    getEmailTemplates(templateId)
      .then((response) => setTemplate(response.data))
      .catch((error) => console.log(error))
  }, [])

  const editTemplate = () => {
    emailEditor?.exportHtml((data) => {
      createOrUpdateEmailTemplates({
        ...template,
        unlayerConfig: data
      })
        .catch((error) => console.log(error))
    })
  }

  const onLoad = (editor) => {
    setEmailEditor(editor)
    if (!template) return
    if (!template.unlayerConfig) return
    if (!template.unlayerConfig.design) return
    editor.loadDesign(template.unlayerConfig.design)
    editor.setMergeTags(mergeTags)
  }

  const RightContent = () => (
    <>
      <CustomButton variant='ghost' handleClick={() => history.goBack()}>
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          Go back
        </span>
      </CustomButton>
      <CustomButton variant='outline' handleClick={() => setIsSliderOpen(true)}>
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          Create Campaign
        </span>
      </CustomButton>
      <CustomButton variant='outline' handleClick={() => setIsSendTestEmailOpen(true)}>
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          <SendHorizonal size={16} />
          Test
        </span>
      </CustomButton>
      <CustomButton variant='outline' handleClick={() => setIsEmailsPreviewOpen(true)}>
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          <EyeIcon size={16} />
          Preview
        </span>
      </CustomButton>
      <CustomButton handleClick={editTemplate}>
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          Save
        </span>
      </CustomButton>
    </>
  )
  if (!template) return null
  return (
    <>
      <div className='flex flex-col relative h-full'>
        <PageHeader
          title={template?.name ? `Edit Email Template: ${template?.name}` : 'Edit Email Template'}
          rightContent={<RightContent />}
        />
        <div className='flex flex-row h-full'>
          <MergeFieldsAndTemplates mergeTags={availableMergeFields} />
          {template && (
            <EmailEditor
              options={{
                displayMode: 'email',
                mergeTags: {
                  'board.name': {
                    name: 'Board Name',
                    value: '{{board.name}}',
                    sample: '{{board.name}}'
                  }
                }
              }}
              onReady={() => {
                console.log('onReady')
              }}
              onLoad={onLoad}
              appearance={{
                theme: 'modern_light'
              }}
            />
          )}
        </div>
      </div>
      <CreateEmailCampaign onClose={() => setIsSliderOpen(false)} open={isSliderOpen} emailTemplate={template} />
      <SendTestEmail
        emailSubject={template?.subject}
        templateId={templateId}
        onClose={() => setIsSendTestEmailOpen(false)} open={isSendTestEmailOpen} />
      <EmailTemplatePreview selectedTemplate={template} open={isEmailsPreviewOpen} onClose={() => {
        setIsEmailsPreviewOpen(false)
      }} />
    </>
  )
}

export default EditEmailTemplate
