/* eslint-disable */
// @ts-nocheck
import { api, ApiErrors } from "services/api";

export const getAllLeadStatuses = () =>
  new Promise((resolve, reject) => {
    api.get(`lead-status-settings`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getAllDealTargets = () =>
  new Promise((resolve, reject) => {
    api.get(`deal-targets-settings`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getAllLeadStatusesOrganized = () =>
  new Promise((resolve, reject) => {
    api.get(`lead-status-settings/organized`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const updateLeadStatusAll = (data) =>
  new Promise((resolve, reject) => {
    api.put(`lead-status-settings/reorder`, data).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const updateDealTargetsAll = (data) =>
  new Promise((resolve, reject) => {
    api.put(`deal-targets-settings/reorder`, data).then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          ApiErrors(response);
          reject();
        }
      });
  });
