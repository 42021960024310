// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Label,
  Pie,
  PieChart,
  BarChart,
  Bar,
} from 'recharts';
import { api } from "services/api";
import { Card, CardContent, CardHeader, CardTitle } from '../../../components/ShadcnComponents/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '../../../components/ShadcnComponents/chart';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../../components/ShadcnComponents/tabs';
import moment from 'moment';
import { FadedAnimatedDiv, LottieWrapper } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
import { DateRangePicker } from 'rsuite';

const GOOGLE_ANALYTICS_BE_URL = process.env.REACT_APP_GOOGLE_ANALYTICS_BE_URL ?? '';
const PROPERTY_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID ?? '';

const TABS = [
  {
    id: 'today',
    label: 'Today',
  },
  {
    id: 'lastSevenDays',
    label: 'Last 7 Days',
  },
  {
    id: 'custom',
    label: 'Custom',
  },
];

const ChartGroup = ({
  chartData,
  totalBounceRate,
  totalUniqueVisitors,
  totalSessions,
  isFetching = false,
}) => {
  const [activeChart, setActiveChart] = React.useState('totalUniqueVisitors');

  const chartConfig = {
    totalUniqueVisitors: {
      label: 'Unique visitors',
      color: 'hsl(var(--chart-1))',
    },
    totalSessions: {
      label: 'Sessions',
      color: 'hsl(var(--chart-2))',
    },
    totalBounceRate: {
      label: 'Bounce rate',
      color: 'hsl(var(--chart-3))',
    },
  }

  const formatLabel = (value) => {
    return new Date(value).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <Card className="md:col-span-2 flex flex-col gap-5">
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="w-full grid grid-cols-3">
          {['totalUniqueVisitors', 'totalSessions', 'totalBounceRate'].map(
            (key) => {
              return (
                <div
                  key={key}
                  data-active={activeChart === key}
                  className="flex flex-1 flex-col justify-center gap-1 p-3 text-left odd:border-none even:border-x data-[active=true]:bg-zinc-100 sm:border-t-0 sm:px-5 sm:py-4 cursor-pointer"
                  onClick={() => setActiveChart(key)}
                >
                  <span className="text-xs">
                    {chartConfig[key].label}
                  </span>
                  <span className="text-lg font-bold leading-none md:text-2xl">
                    {key === 'totalUniqueVisitors' && totalUniqueVisitors}
                    {key === 'totalSessions' && totalSessions}
                    {key === 'totalBounceRate' &&
                      (totalBounceRate * 100).toFixed(2) + '%'}
                  </span>
                </div>
              );
            }
          )}
        </div>
      </CardHeader>
      {isFetching ? (
        <FadedAnimatedDiv
          key='loadingTable'
          className='h-[414px] w-full flex justify-center items-center'
        >
          <LottieWrapper
            animation={tableLoading}
            loop
            className='h-16 w-16'
          />
          <span className='text-base dark:text-white'>loading...</span>
        </FadedAnimatedDiv>
      ) : (
        <>
          {chartData?.length > 0 ? (
            <>
              <CardContent className="px-2 py-3.5">
                <ChartContainer
                  config={chartConfig}
                  className="h-[400px] w-full"
                  id="numberOfVisitorChart"
                >
                  <BarChart
                    accessibilityLayer
                    data={chartData}
                    margin={{
                      left: 12,
                      right: 12,
                    }}
                  >
                    <CartesianGrid vertical={false} />
                    <XAxis
                      dataKey="date"
                      tickLine={false}
                      axisLine={false}
                      tickMargin={8}
                      minTickGap={32}
                      tickFormatter={(value) => {
                        const date = new Date(value);
                        return date.toLocaleDateString('en-US', {
                          month: 'short',
                          day: 'numeric',
                        });
                      }}
                    />
                    {
                      activeChart === 'totalBounceRate' &&
                      <YAxis
                        domain={[0, 100]}
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value) => `${value}%`}
                      />
                    }
                    <ChartTooltip
                      content={
                        <ChartTooltipContent
                          className="w-[150px]"
                          // nameKey="views"
                          labelFormatter={formatLabel}
                        />
                      }
                    />
                    <Bar
                      dataKey={activeChart}
                      fill={`var(--color-${activeChart})`}
                    />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </>
          ) : (
            <div className="text-center h-[414px] w-full text-base font-medium text-placeholder-gray flex justify-center items-center">
              There&apos;s no information to display at this time
            </div>
          )}
        </>
      )}
    </Card>
  );
};

const MetricBox = ({ label, value }) => (
  <div className="flex flex-1 flex-col justify-center gap-1 py-2 px-3 text-left bg-zinc-100 rounded-md">
    <span className="text-xs text-muted-foreground">{label}</span>
    <span className="text-lg font-bold leading-none sm:text-xl">{value}</span>
  </div>
);

const NewVsRepeaterVisitorsPieChart = ({
  totalUniqueVisitors = 0,
  totalRepeaterVisitors = 0,
  totalSessions = 0,
  isFetching = false,
}) => {
  const chartData = useMemo(() => {
    if (totalSessions) {
      return [
        {
          label: 'Unique Visitors',
          type: 'totalUniqueVisitors',
          visitors: totalUniqueVisitors,
          fill: 'var(--color-totalUniqueVisitors)',
        },
        {
          label: 'Repeater Visitors',
          type: 'totalRepeaterVisitors',
          visitors: totalRepeaterVisitors,
          fill: 'var(--color-totalRepeaterVisitors)',
        },
      ];
    }

    return [];
  }, [isFetching, totalSessions, totalUniqueVisitors, totalRepeaterVisitors]);

  const chartConfig = {
    visitors: {
      label: 'Visitors',
    },
    totalUniqueVisitors: {
      label: 'Unique Visitors',
      color: 'hsl(var(--chart-1))',
    },
    totalRepeaterVisitors: {
      label: 'Repeater Visitors',
      color: 'hsl(var(--chart-2))',
    },
  };

  const totalVisitors = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.visitors, 0);
  }, [isFetching, totalSessions, chartData]);

  return (
    <Card className="md:col-span-2 flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle className="text-lg text-center">
          Unique vs Repeater Visitors Insights
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-1 pb-6">
        {isFetching ? (
          <FadedAnimatedDiv
            key='loadingTable'
            className='h-[250px] w-full flex justify-center items-center'
          >
            <LottieWrapper
              animation={tableLoading}
              loop
              className='h-16 w-16'
            />
            <span className='text-base dark:text-white'>loading...</span>
          </FadedAnimatedDiv>
        ) : (
          <>
            {totalSessions > 0 ? (
              <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square max-h-[250px]"
                id="newVsRepeatersPieChart"
              >
                <PieChart>
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Pie
                    data={chartData}
                    dataKey="visitors"
                    nameKey="type"
                    innerRadius={60}
                    strokeWidth={5}
                  >
                    <Label
                      content={({ viewBox }) => {
                        if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                          return (
                            <text
                              x={viewBox.cx}
                              y={viewBox.cy}
                              textAnchor="middle"
                              dominantBaseline="middle"
                            >
                              <tspan
                                x={viewBox.cx}
                                y={viewBox.cy}
                                className="fill-foreground text-2xl font-bold"
                              >
                                {totalVisitors.toLocaleString()}
                              </tspan>
                              <tspan
                                x={viewBox.cx}
                                y={(viewBox.cy || 0) + 24}
                                className="fill-muted-foreground"
                              >
                                Visitors
                              </tspan>
                            </text>
                          );
                        }
                      }}
                    />
                  </Pie>
                  <ChartLegend
                    className="pt-6"
                    content={<ChartLegendContent />}
                  />
                </PieChart>
              </ChartContainer>
            ) : (
              <div className="text-center h-[250px] w-full text-base font-medium text-placeholder-gray flex justify-center items-center">
                There&apos;s no information to display at this time
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const AdminWebsiteTrafficReport = () => {
  const [dateRangeQuery, setDateRangeQuery] = useState(
    `&startDate=${moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')}&endDate=${moment().endOf('day').format('YYYY-MM-DD')}`
  );
  const [numberOfVisitorChartData, setNumberOfVisitorChartData] = useState(
    []
  );
  const [totalBounceRate, setTotalBounceRate] = useState(0);
  const [totalUniqueVisitors, setTotalUniqueVisitors] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);
  const [isFetchingTopPagesTableData, setIsFetchingTopPagesTableData] =
    useState(true);
  const [topPagesTableData, setTopPagesTableData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: moment().subtract(7, 'days').startOf('day').toDate(),
    to: moment().toDate(),
  });
  const [isSelectionBottomSheetOpen, setIsSelectionBottomSheetOpen] =
    useState(false);
  const [isFetchingCityData, setIsFetchingCityData] = useState(true);
  const [isFetchingCountryData, setIsFetchingCountryData] =
    useState(true);
  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const fetchChartData = async () => {
    try {
      setIsFetchingTopPagesTableData(true);
      setIsFetchingCityData(true);
      setIsFetchingCountryData(true);

      // Fetch main chart data
      const chartDataResponse = await fetch(
        `${GOOGLE_ANALYTICS_BE_URL}/google/analytics?propertyId=${PROPERTY_ID}&groupBy=day${dateRangeQuery}`
      );
      if (!chartDataResponse.ok) {
        throw new Error('Failed to fetch chart data');
      }
      const chartData = await chartDataResponse.json();

      setNumberOfVisitorChartData(
        chartData.groups.map((elm) => {
          const { date, totalUniqueVisitors, totalBounceRate, totalSessions } = elm;
          return {
            date,
            totalUniqueVisitors,
            totalBounceRate: (totalBounceRate * 100).toFixed(2),
            totalSessions,
          };
        })
      );
      setTotalBounceRate(chartData.totalBounceRate);
      setTotalUniqueVisitors(chartData.totalUniqueVisitors);
      setTotalSessions(chartData.totalSessions);

      const combinedData = chartData.topPages.reduce((acc, item) => {
        const existing = acc.find((i) => i.pageUrl === item.pageUrl);

        if (existing) {
          existing.uniqueVisitors += item.uniqueVisitors;
          existing.totalVisits += item.totalVisits;
          existing.totalScreenPageViews += item.totalScreenPageViews;
          existing.bounceRate =
            (existing.bounceRate * existing.totalVisits +
              item.bounceRate * item.totalVisits) /
            (existing.totalVisits + item.totalVisits);
        } else {
          const path = new URL(`https://${item.pageUrl}`).pathname;
          acc.push({ ...item, path });
        }

        return acc;
      }, []);
      setTopPagesTableData(
        combinedData.sort((a, b) => b.totalVisits - a.totalVisits)
      );
      setIsFetchingTopPagesTableData(false);

      // Fetch city data
      const cityDataResponse = await fetch(
        `${GOOGLE_ANALYTICS_BE_URL}/google/analytics/city?propertyId=${PROPERTY_ID}&groupBy=day${dateRangeQuery}`
      );
      if (!cityDataResponse.ok) {
        throw new Error('Failed to fetch city data');
      }
      const cityData = await cityDataResponse.json();
      setCityData(
        cityData.data.filter(
          (elm) => elm.city?.length > 0 && elm.city !== '(not set)'
        )
      );
      setIsFetchingCityData(false);

      // Fetch country data
      const countryDataResponse = await fetch(
        `${GOOGLE_ANALYTICS_BE_URL}/google/analytics/country?propertyId=${PROPERTY_ID}&groupBy=day${dateRangeQuery}`
      );
      if (!countryDataResponse.ok) {
        throw new Error('Failed to fetch country data');
      }
      const countryData = await countryDataResponse.json();
      setCountryData(countryData.data);
      setIsFetchingCountryData(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsFetchingTopPagesTableData(false);
      setIsFetchingCityData(false);
      setIsFetchingCountryData(false);
    }
  };

  useEffect(() => {
    if (GOOGLE_ANALYTICS_BE_URL && PROPERTY_ID) {
      fetchChartData();
    } else {
      setIsFetchingTopPagesTableData(false);
      setIsFetchingCityData(false);
      setIsFetchingCountryData(false);
    }
  }, [dateRangeQuery]);

  return (
    <div className="container w-full grid grid-cols-1 md:grid-cols-2 gap-4 py-6">
      <div className="md:col-span-2 flex flex-col gap-3">
        <h1 className="text-2xl font-semibold mb-0">Website traffic report</h1>
        <div className="w-fit">
          <Tabs defaultValue="lastSevenDays" className="h-fit">
            <TabsList className="w-full h-full flex justify-start gap-2 p-0 bg-transparent overflow-x-auto hide-scrollbar">
              {TABS.map((tab, index) => (
                <TabsTrigger
                  key={index}
                  value={tab.id}
                  className="focus-visible:ring-0 py-2 px-4 text-sm font-medium leading-[20px] text-eerie-lack rounded-full border border-platinum-gray transition-all data-[state=active]:bg-eerie-lack data-[state=active]:text-lotion-gray data-[state=active]:shadow-none data-[state=active]:border-eerie-lack"
                  onClick={() => {
                    if (tab.id === 'today') {
                      setDateRangeQuery(
                        `&startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`
                      );
                    } else if (['lastSevenDays', 'custom'].includes(tab.id)) {
                      setDateRangeQuery(
                        `&startDate=${moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD')}&endDate=${moment().endOf('day').format('YYYY-MM-DD')}`
                      );
                    }
                  }}
                >
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>
            <TabsContent value="custom" className="py-2 max-w-lg">
              <div className="flex flex-col gap-y-1 w-full">
                <div className="text-sm font-semibold">Start Date</div>
                <DateRangePicker
                  placeholder="Week picker"
                  value={[
                    selectedDateRange.from,
                    selectedDateRange.to
                  ]}
                  onChange={(value) => {
                    if (value[0] > new Date()) {
                      toast.error("Date cannot be greater than today");
                      return;
                    }
                    setSelectedDateRange({
                      from: moment(value[0]).toDate(),
                      to: moment(value[1]).toDate()
                    });
                    setDateRangeQuery(
                      `&startDate=${moment(value[0]).format('YYYY-MM-DD')}&endDate=${moment(value[1]).format('YYYY-MM-DD')}`
                    );
                  }}
                  cleanable={false}
                />
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
      <ChartGroup
        chartData={numberOfVisitorChartData}
        totalBounceRate={totalBounceRate}
        totalUniqueVisitors={totalUniqueVisitors}
        totalSessions={totalSessions}
        isFetching={isFetchingTopPagesTableData}
      />
      <NewVsRepeaterVisitorsPieChart
        totalUniqueVisitors={totalUniqueVisitors}
        totalRepeaterVisitors={totalSessions - totalUniqueVisitors}
        totalSessions={totalSessions}
        isFetching={isFetchingTopPagesTableData}
      />
      <Card className="md:col-span-2 p-3 flex flex-col gap-4">
        <CardHeader className="p-0 text-lg font-medium">Top Pages</CardHeader>
        <CardContent className="p-0 w-full grid grid-cols-1 md:grid-cols-2 gap-3 max-h-[650px] overflow-y-auto">
          {isFetchingTopPagesTableData ? (
            <FadedAnimatedDiv
              key='loadingTable'
              className='md:col-span-2 h-[200px] w-full flex justify-center items-center'
            >
              <LottieWrapper
                animation={tableLoading}
                loop
                className='h-16 w-16'
              />
              <span className='text-base dark:text-white'>loading...</span>
            </FadedAnimatedDiv>
          ) : (
            <>
              {topPagesTableData?.length > 0 ? (
                <>
                  {topPagesTableData.map((page, index) => (
                    <div
                      key={index}
                      className="w-full p-3 border rounded-lg flex flex-col gap-x-3 gap-y-2"
                    >
                      <p className="flex gap-1.5 mb-0">
                        <span className="text-placeholder-gray">Page:</span>
                        {page.path}
                      </p>
                      <div className="w-full grid grid-cols-2 gap-3">
                        <MetricBox label="Visitors" value={page.totalVisits} />
                        <MetricBox
                          label="Unique visitors"
                          value={page.uniqueVisitors}
                        />
                        <MetricBox
                          label="Bounce rate"
                          value={(page.bounceRate * 100).toFixed(2) + '%'}
                        />
                        <MetricBox
                          label="Avg. screen time"
                          value={moment
                            .utc(page.averageSessionDuration * 1000)
                            .format('HH:mm:ss')}
                        />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="md:col-span-2 text-center h-[200px] w-full text-base font-medium text-placeholder-gray flex justify-center items-center">
                  There&apos;s no information to display at this time
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
      <Card className="p-3 flex flex-col gap-4">
        <CardHeader className="w-full p-0">
          <div className="w-full flex justify-between items-center text-lg font-medium px-3">
            <span>Country</span>
            <span>Visitors</span>
          </div>
        </CardHeader>
        <CardContent className="p-0 w-full flex flex-col gap-3 max-h-[628px] overflow-hidden">
          {isFetchingCountryData ? (
            <FadedAnimatedDiv
              key='loadingTable'
              className='h-[200px] w-full flex justify-center items-center'
            >
              <LottieWrapper
                animation={tableLoading}
                loop
                className='h-16 w-16'
              />
              <span className='text-base dark:text-white'>loading...</span>
            </FadedAnimatedDiv>
          ) : (
            <div className="h-fit w-full flex flex-col gap-3 overflow-y-auto">
              {countryData?.length > 0 ? (
                <>
                  {countryData.map((item, index) => (
                    <div
                      key={index}
                      className="w-full z-[1] min-h-[50px] relative p-3 border rounded-lg flex justify-between gap-3 font-medium overflow-hidden bg-transparent"
                    >
                      <p className="mb-0">{item.country}</p>
                      <p className="mb-0">{item.totalVisits}</p>
                      <div
                        className="z-[-1] absolute top-0 left-0 bg-zinc-100 h-full"
                        style={{
                          width: `${(item.totalVisits / countryData[0].totalVisits) * 100}%`,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div className="text-center h-[200px] w-full text-base font-medium text-placeholder-gray flex justify-center items-center">
                  There&apos;s no information to display at this time
                </div>
              )}
            </div>
          )}
        </CardContent>
      </Card>
      <Card className="p-3 flex flex-col gap-4">
        <CardHeader className="w-full p-0">
          <div className="w-full flex justify-between items-center text-lg font-medium px-3">
            <span>City</span>
            <span>Visitors</span>
          </div>
        </CardHeader>
        <CardContent className="p-0 w-full flex flex-col gap-3 max-h-[628px] overflow-y-auto">
          {isFetchingCityData ? (
            <FadedAnimatedDiv
              key='loadingTable'
              className='h-[200px] w-full flex justify-center items-center'
            >
              <LottieWrapper
                animation={tableLoading}
                loop
                className='h-16 w-16'
              />
              <span className='text-base dark:text-white'>loading...</span>
            </FadedAnimatedDiv>
          ) : (
            <>
              {cityData?.length > 0 ? (
                <>
                  {cityData.map((item, index) => (
                    <div
                      key={index}
                      className="w-full z-[1] min-h-[50px] relative p-3 border rounded-lg flex justify-between gap-3 font-medium overflow-hidden bg-transparent"
                    >
                      <p className="mb-0">{item.city}</p>
                      <p className="mb-0">{item.totalVisits}</p>
                      <div
                        className="z-[-1] absolute top-0 left-0 bg-zinc-100 h-full"
                        style={{
                          width: `${(item.totalVisits / cityData[0].totalVisits) * 100}%`,
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div className="text-center h-[200px] w-full text-base font-medium text-placeholder-gray flex justify-center items-center">
                  There&apos;s no information to display at this time
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminWebsiteTrafficReport;
