import OpenSidebarButton from 'components/OpenSidebarButton'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

const MobilePageHeader = () => {
  // @ts-ignore
  const appReducer = useSelector(state => state.appReducer)
  const [projectName, setProjectName] = useState('')
  const { view } = useContactContext()
  useEffect(() => {
    const list = appReducer?.listOfProjects
    const selectedProjectId = appReducer?.appProject

    if (!list || !selectedProjectId) return

    const activeProject = list.find(project => project._id === selectedProjectId)
    setProjectName(activeProject?.projectName)
  }, [appReducer])

  return (
    <div style={{ zIndex: 99999, width: `${isMobile || !(appReducer.show && !appReducer.isHover) ? '100%' : 'calc(100% - 281px)'}` }} className='fixed bg-[#18181B] flex justify-center items-center font-bold text-2xl text-white px-6 py-6 border-b-2 border-softBlack h-16 transition-all ease-in-out duration-300'>
      <OpenSidebarButton />
      <div className='whitespace-nowrap ml-4'>
        {
          view === 'corporate' ? 'Corporate View' : projectName
        }
      </div>
    </div>
  )
}

export default MobilePageHeader
