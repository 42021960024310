// @ts-nocheck
/* eslint-disable */
import { TrendingUp } from "lucide-react"
import { Bar, BarChart, CartesianGrid, Rectangle, XAxis, YAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ShadcnComponents/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ShadcnComponents/chart"
import React from "react"

export const description = "A multiple bar chart"

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Mobile",
    color: "hsl(var(--chart-2))",
  },
}

export function BarChartShadCN({
  data,
  XAxisDataKey,
  XAxisTickFormatter,
  dataKey1,
  dataKey2,
  interval,
  angle,
  textAnchor,
  height,
  tickStyle,
  tickMargin = 10,
  ChartContainerProps,
  title,
  description,
  footer,
  barWidth = 10,
}) {
  return (
    <Card className="bg-white rounded-lg h-full">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="h-full min-h-[200px]">
        <ChartContainer config={chartConfig} style={{height:"300px",width:"100%"}}>
          <BarChart 
          className="h-full"
          accessibilityLayer data={data}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={XAxisDataKey}
              tickLine={false}
              axisLine={false}
              tickMargin={tickMargin}
              height={height}
              interval={interval}
              angle={angle}
              textAnchor={textAnchor}
              tickFormatter={XAxisTickFormatter}
              tick={tickStyle}
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent indicator="solid" className="capitalize" />
              }
            />
            <Bar
              dataKey={dataKey1}
              fill="var(--color-desktop)"
              width={barWidth}
              radius={4}
            />
            <Bar
              dataKey={dataKey2}
              fill="var(--color-mobile)"
              width={barWidth}
              radius={4}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
      {footer && (
        <CardFooter className="flex-col items-start gap-2 text-sm">
          {footer}
        </CardFooter>
      )}
    </Card>
  )
}
