import { api, ApiErrors } from 'services/api'

export const getSenderAccounts = (email) =>
  new Promise((resolve, reject) => {
    const params = {
      email
    }
    api.get('/sendgrid-accounts', params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
