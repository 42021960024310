// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import UseCSV from '@usecsv/react';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import CustomButton from 'components/CustomButton';
import useGetLists from 'hooks/useGetLists';
import { Select } from 'antd';
import Checkbox from 'components/Checkbox';
import AddOrEditBulkContact from 'components/AddBulkContact';

const dynamicColumnsForBuyer = [
  {
    name: 'firstName',
    displayName: 'First Name',
    example: 'John',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'lastName',
    displayName: 'Last Name',
    example: 'Doe',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'email',
    displayName: 'Email',
    example: 'john@example.com',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'email' },
  },
  {
    name: 'userType',
    displayName: 'User Type',
    example: 'Buyer',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'phoneNumber',
    displayName: 'Phone Number',
    example: '123-456-7890',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'leadSource',
    displayName: 'Lead Source',
    example: 'Source',
    // mustBeMatched: true,
    // valueCannotBeBlank: true,
    validationFormat: { type: 'string' },
  },
  {
    name: 'city',
    displayName: 'City',
    example: 'New York',
    validationFormat: { type: 'string' },
  },
  {
    name: 'province',
    displayName: 'State',
    example: 'NY',
    validationFormat: { type: 'string' },
  },
  {
    name: 'postalCode',
    displayName: 'Postal Code',
    example: '10001',
    validationFormat: { type: 'string' },
  },
  {
    name: 'country',
    displayName: 'Country',
    example: 'USA',
    validationFormat: { type: 'string' },
  },
  {
    name: 'address',
    displayName: 'Address',
    example: '123 Main St',
    validationFormat: { type: 'string' },
  },
  {
    name: 'createdAt',
    displayName: 'Created At',
    example: '2021-01-01',
    validationFormat: { type: 'string' },
  },
  {
    name: 'are_you_realtor',
    displayName: 'Are you a realtor?',
    example: 'Yes',
    validationFormat: { type: 'string' },
  },
];

const AddBulkContact = ({ show, onSuccess, setShow, project, eventId }) => {
  const [isCSVModalDisplay, setIsCSVModalDisplay] = useState(false);

  const { view } = useContactContext();
  const renderButton = (openModal) => {
    return (
      <>
        {!isCSVModalDisplay && (
          <CustomButton
            handleClick={() => {
              openModal();
              setIsCSVModalDisplay(true);
            }}
            className=""
          >
            <span className="font-medium py-3 px-6 text-base flex gap-2 items-center">
              Start importing bulk attendees
            </span>
          </CustomButton>
        )}
      </>
    );
  };

  const handleClose = () => {
    setShow(false);
    setIsCSVModalDisplay(false);
    onSuccess();
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="Add Or Edit Bulk Contact Modal"
      aria-describedby="Add Or Edit Bulk Contact description"
      className="!z-[100]"
    >
      <div
        className="relative flex flex-col font-openSans bg-grey-8 p-10 z-[10] rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className="absolute right-10" onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className="font-bold text-xl text-center">Bulk Upload Attendee</h1>
        <div className="relative w-full h-full flex flex-col gap-4 justify-center items-center">
          <UseCSV
            importerKey={process.env.REACT_APP_BULK_ATTENDEE_IMPORTER_KEY}
            dynamicColumns={dynamicColumnsForBuyer}
            render={(openModal) => renderButton(openModal)}
            importerDisplay="inline"
            onClose={handleClose}
            metadata={{
              project: view === 'project' ? project : null,
              eventId
            }}
          />
          {isCSVModalDisplay && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              id="usecsv-importer-inline-wrapper"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddBulkContact;
