/*eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { createAttendees } from 'store/actions/eventsActions'
import CustomButton from 'components/CustomButton'
import { message, Select } from 'antd'
import { getSearchContacts, getUsers, getFilterContacts } from 'store/actions/usersActions'
import { ReactComponent as DownIcon } from '../../../../../assets/icons/down.svg';
import useUpdateUsers from 'hooks/useUpdateUsers'

const attendeeSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required')
})

const AddAttendeeModal = ({
  onClose,
  show,
  eventId,
  refreshData,
  setIsShowCreateAttendeeModal
}) => {
  const { createOrUpdateUser } = useUpdateUsers(false)
  const projectId = useSelector(state => state.appReducer.appProject)
  const [attendee, setAttendee] = useState({
    _id: '',
    event: eventId,
    status: '',
    user: '',
    checkIn: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  })
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [contact,setContact] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSearchUser = async (value) => {
    let { data } = await getFilterContacts({
      search: value,
      page:0,
      limit:1000,
      project:projectId,
      userType: 'Default'
    });

    const formattedData = data.map((user) => {
      return {
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
        _id: user._id,
        id: user._id,
      };
    });
    setSearchedUsers(formattedData);
  };

  const onChange = async (fieldName, value) => {
    setSelectedUsers(value);
    setUserInfo(null);
    const data = await getUsers(value);
    setUserInfo(data);
  };

  useEffect(() => {
    if (projectId) {
      setAttendee({
        ...attendee,
        _id: projectId
      })
    }
  }, [projectId])

  useEffect(() => {
    handleSearchUser('')
  }, [show])

  const handleBlur = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true
    })
    attendeeSchema
      .validate(attendee, { abortEarly: false })
      .catch((validationErrors) => {
        const errors = {}
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message
        })
        setErrors({ ...errors })
      })
  }

  const handleChange = (e) => {
    setAttendee({
      ...attendee,
      [e.target.name]: e.target.value
    })
  }

  const handleAttendeeSubmit = () => {
    if(!selectedUsers) return;
    setLoading(true);
    createAttendees({ user:selectedUsers,event: eventId }).then((data) => {
      setSearchedUsers([]);
      setSelectedUsers([]);
      setUserInfo(null);
      refreshData();
      setIsShowCreateAttendeeModal(false);
      setLoading(false);
    })
      .catch((validationErrors) => {
        const errors = {}
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message
        })
        setLoading(false);
        setErrors(errors)
      })
  }

  const handleCreateAttendee = async () => {
    attendeeSchema
      .validate(attendee, { abortEarly: false })
      .then(async () => {
        const buyerParams = {
          firstName: attendee.firstName,
          lastName: attendee.lastName,
          email: attendee.email,
          project: projectId,
          userType: 'Buyer',
          buyerData: {
            phoneNumber: attendee.phoneNumber,
            additionalFields: [],
            leadSource: 'Event',
            assignments: [
              {
                salesRep: null,
              },
            ],
          },
          salesRep: null,
        };
        try {
          setLoading(true);
          const res = await createOrUpdateUser(buyerParams).catch((e)=>console.log(e));
          if(!res){
            setLoading(false);
            return message.error('User already exists, search and add attendee.');
          } 
    
          createAttendees({ user: res._id, event: eventId })
            .then((data) => {
              setSearchedUsers([]);
              setUserInfo(null);
              refreshData();
              setAttendee({
                _id: '',
                event: eventId,
                status: '',
                user: '',
                checkIn: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: ''
              })
              setIsShowCreateAttendeeModal(false);
              setLoading(false);
            })
            .catch((validationErrors) => {
              const errors = {};
              validationErrors.inner.forEach((err) => {
                errors[err.path] = err.message;
              });
              setErrors(errors);
              setLoading(false);
            });
            setIsShowCreateAttendeeModal(false);
        } catch (error) {
          setLoading(false);
          console.log('error');
          console.log(error);
        }
      })
      .catch((validationErrors) => {
        console.log(validationErrors);
        const errors = {};
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
        setErrors({ ...errors });
        setLoading(false);
      });
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Private Document Upload Modal'
      aria-describedby='Private Document Edit Modal for uploading document'
    >
      <div
        className='relative flex flex-col font-openSans bg-white p-8 rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ maxWidth: '760px' }}
      >
        <div className='flex flex-col gap-5 relative justify-center items-center rounded-3xl'>
          <div className='flex flex-col items-center text-center w-4/5'>
            <h1 className='text-2xl text-italic'>Add Attendee</h1>
            <button className='absolute top-0 right-0' onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div className='flex-1 w-full'>
          <div className="flex flex-col lg:flex-row items-center lg:items-stretch space-y-2 lg:space-y-0 lg:space-x-4">
            <div className="flex-1 p-3 w-full">
              <p className='font-semibold text-xl'>Search Attendee</p>

              <div className='text-softBlack70 text-xs font-medium uppercase'>
                  Contact *
                </div>
                <Select
                  style={{
                    width: '100%',
                  }}
                  showArrow
                  placeholder='Search contacts'
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(value) => {
                    onChange('buyer', value);
                  }}
                  value={
                    selectedUsers?.value == ''
                      ? 'Select Contact'
                      : selectedUsers?.value
                  }
                  className='ant-picker-input1'
                  suffixIcon={<DownIcon />}
                  showSearch={true}
                  onSearch={(value) => {
                    handleSearchUser(value);
                  }}
                  filterOption={false}
                  optionFilterProp='_id'
                >
                  {searchedUsers?.map((contact, index) => (
                    <Select.Option key={index} label={contact.label} value={contact.value}>
                      {contact.label}
                    </Select.Option>
                  ))}
                </Select>
                <br />
                {selectedUsers.length > 0 && !userInfo && <p className='text-sm text-grey-500'>Loading....</p>}
                {userInfo && <>
                    <p>
                      {'Name: ' + userInfo.firstName } <br/>
                      {'User: ' + userInfo.userType } <br/>
                      {'Email: ' + userInfo.email } <br/>
                      Phone:  + {userInfo.userType == 'Buyer' ? userInfo?.buyerData?.phoneNumber : userInfo?.brokerData?.phoneNumber } <br/>
                    </p>
                </>}

              {userInfo && <CustomButton disabled={loading} handleClick={handleAttendeeSubmit}>
                <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                  Add Attendee
                </span>
              </CustomButton>
              }
            </div>

            <div className="lg:block w-px bg-gray-300"></div>

            <div className="flex-1 p-3 w-full">
              <p className='font-semibold text-xl'>Create Attendee</p>

              <div className=''>
                 <label className='text-[2E2B2E] font-normal text-base italic'>
                   First Name *
                 </label>
                 <div>
                   <input
                    type='text'
                    name='firstName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='first name'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.firstName && errors.firstName && <div className=''><p className='text-red-500'>{errors.firstName}</p></div>}
                </div>
              </div>
              <div className=''>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Last Name *
                </label>
                <div>
                  <input
                    type='text'
                    name='lastName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='last name'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.lastName && errors.lastName && <div className=''><p className='text-red-500'>{errors.lastName}</p></div>}
                </div>
              </div>
              <div className=''>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Email *
                </label>
                <div>
                  <input
                    type='email'
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='email'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.email && errors.email && <div className=''><p className='text-red-500'>{errors.email}</p></div>}
                </div>
              </div>
              <div className='mb-2'>
                 <label className='text-[2E2B2E] font-normal text-base italic'>
                   Phone
                 </label>
                 <div>
                   <input
                    type='text'
                    name='phoneNumber'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='phone'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.phoneNumber && errors.phoneNumber && <div className=''><p className='text-red-500'>{errors.phoneNumber}</p></div>}
                </div>
              </div>

              {
                <CustomButton disabled={loading} handleClick={handleCreateAttendee}>
                <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                  Create Attendee
                </span>
                </CustomButton>
              }
            </div>
          </div>
            {/* <div className='col-span-7 gap-y-6 grid grid-cols-6 gap-x-4 lg:gap-x-6'>
              <div className='col-span-3 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  First Name *
                </label>
                <div>
                  <input
                    type='text'
                    name='firstName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='first name'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.firstName && errors.firstName && <div className='relative h-0'><p className='absolute text-red-500'>{errors.firstName}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Last Name *
                </label>
                <div>
                  <input
                    type='text'
                    name='lastName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='last name'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.lastName && errors.lastName && <div className='relative h-0'><p className='absolute text-red-500'>{errors.lastName}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Email *
                </label>
                <div>
                  <input
                    type='email'
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='email'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.email && errors.email && <div className='relative h-0'><p className='absolute text-red-500'>{errors.email}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Status *
                </label>
                <div>
                  <input
                    type='text'
                    name='status'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='status'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.status && errors.status && <div className='relative h-0'><p className='absolute text-red-500'>{errors.status}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  User *
                </label>
                <div>
                  <input
                    type='text'
                    name='user'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='user'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.user && errors.user && <div className='relative h-0'><p className='absolute text-red-500'>{errors.user}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Check-In *
                </label>
                <div>
                  <input
                    type='text'
                    name='checkIn'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='08/05/2023 03:05 PM'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.checkIn && errors.checkIn && <div className='relative h-0'><p className='absolute text-red-500'>{errors.checkIn}</p></div>}
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className='flex justify-center gap-6 w-full mt-4 items-center'>
            <CustomButton variant='outline' handleClick={onClose}>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                Close
              </span>
            </CustomButton>
            <CustomButton handleClick={handleSubmit}>
              <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                Add Attendee
              </span>
            </CustomButton>
          </div> */}
        </div>
      </div>
    </Modal>
  )
}
export default AddAttendeeModal
