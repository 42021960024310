/*eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { updateAttendees } from 'store/actions/eventsActions'
import CustomButton from 'components/CustomButton'
import { Select } from 'antd'
import { getSearchContacts, getUsers } from 'store/actions/usersActions'
import { ReactComponent as DownIcon } from '../../../../../assets/icons/down.svg';
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import moment from "moment-timezone";

const attendeeSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  user: Yup.string().required('User is required'),
  checkIn: Yup.string().required('Check-in Image is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().required('Email is required')
})

const CheckinModal = ({
  onClose,
  show,
  selectedAttendee,
  eventId,
  refreshData,
  setIsShowCheckinModal
}) => {
  const projectId = useSelector(state => state.appReducer.appProject)
  const [errors, setErrors] = useState({})
  const [date_time, setDateTime] = useState(moment().toDate())

  const onChange = async (value) => {
    console.log(value)
    setDateTime(moment(value).toDate())
  };

  const handleSubmit = () => {
    updateAttendees({...selectedAttendee,attendeeId:selectedAttendee._id, checkIn:'Completed',checkInTime:date_time.toISOString() }, 'checkin').then((data) => {
      refreshData()
      setIsShowCheckinModal(false)
    }
    )
    .catch((validationErrors) => {
      const errors = {}
      validationErrors.inner.forEach((err) => {
        errors[err.path] = err.message
      })
      setErrors(errors)
    })
  }

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Private Document Upload Modal'
      aria-describedby='Private Document Edit Modal for uploading document'
    >
      <div
        className='relative flex flex-col font-openSans bg-white p-8 rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: '430px' }}
      >
        <div className='flex flex-col gap-5 relative justify-center items-center rounded-3xl'>
          <div className='flex flex-col items-center text-center w-4/5'>
            <h1 className='text-2xl text-italic'>Check In</h1>
            <button className='absolute top-0 right-0' onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div className='flex-1 w-full'>
            <div className="flex-1 p-3 w-full">
              <p className='font-semibold text-xl'>{ selectedAttendee?.user?.fullName }</p>
                <DatePicker
                format="MM-dd-yyyy"
                placeholder="select date"
                value={date_time}
                oneTap={true}
                onChange={(e) => 
                  onChange(e)
                }
                cleanable={false}
              />
                <br />
                <CustomButton handleClick={handleSubmit} className='mt-2'>
                <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                  Check In
                </span>
                </CustomButton>
                
          </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default CheckinModal
