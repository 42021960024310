/* eslint-disable */
// @ts-nocheck
import QuickForm from "components/Inventory/components/QuickForm"
import React from "react"
import { useParams } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const PurchaseForm = () => {
  const location = useLocation()
  const defaultSelectedUnit = new URLSearchParams(location.search).get(
    "defaultSelectedUnit"
  )
  return (
    <div className="bg-[#F6F5F1]">
      <QuickForm defaultSelectedUnit={defaultSelectedUnit} />
    </div>
  )
}

export default PurchaseForm
