/* eslint-disable */
// @ts-ignore
import { useCallback, useEffect, useState } from 'react'
import useWorkFlows from 'hooks/useCustomWorkflows'
// @ts-ignore
import { v4 } from 'uuid'
import {
    ReactFlow,
    ReactFlowProvider,
    Background,
    BackgroundVariant,
    useNodesState,
    useEdgesState,
    MiniMap,
    Controls
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { ArrowLeftIcon, TrashIcon } from 'lucide-react'
import CustomButton from 'components/CustomButton'
import { getEmailTemplates } from 'store/actions/emailTemplateActions'

const newYPositionOffset = 75

export default function ReactFlowProviderComponent({ workflow }) {
    const [editingName, setEditingName] = useState(false)
    const [updatingName, setUpdatingName] = useState(false)
    const [workflowName, setWorkflowName] = useState(workflow?.name)
    const [availableActions, setAvailableActions] = useState([])
    const [availableTriggers, setAvailableTriggers] = useState([])
    const [nodes, setNodes, onNodesChange] = useNodesState(
        workflow?.draftFlow?.nodes || []
    )
    const [edges, setEdges, onEdgesChange] = useEdgesState(
        workflow?.draftFlow?.edges || []
    )
    const [savingDraft, setSavingDraft] = useState(false)
    const [selectedNode, setSelectedNode] = useState(null)

    const [publishing, setPublishing] = useState(false)
    const { getActions, getTriggers, publishWorkflow, updateWorkflow, saveWorkflowDraft } = useWorkFlows()

    useEffect(() => {
        getActions().then((response) => {
            setAvailableActions(response)
        })
        getTriggers().then((response) => {
            setAvailableTriggers(response)
        })
    }, [])

    function saveDraft(callback) {
        setSavingDraft(true)
        saveWorkflowDraft
            (workflow._id, {
                nodes,
                edges
            })
            .then((response) => {
                console.log('response', response)
                if (callback && typeof callback === 'function') {
                    callback?.()
                }
            })
            .finally(() => {
                setSavingDraft(false)
            })
    }

    function saveAndPublish() {
        saveDraft(() => {
            setPublishing(true)
            publishWorkflow(workflow._id).then((response) => {
                console.log('response', response)
            })
                .then(() => {
                    alert('Workflow published successfully')
                })
                .finally(() => {
                    setPublishing(false)
                })
        })

    }

    function resetNodesPositionInColumn(nodes) {
        const newNodes = nodes.map((node, index) => {
            return {
                ...node,
                position: {
                    x: 0,
                    y: index * newYPositionOffset
                }
            }
        })
        setNodes(newNodes)
    }

    function addNewNode(nodeConfig) {
        const lastNode = nodes[nodes.length - 1]
        const position = {
            x: lastNode?.position.x || 0,
            y: lastNode?.position.y || 0
        }
        position.y += newYPositionOffset

        const randomId = v4()
        const newNode = {
            id: randomId,
            type: nodeConfig.type,
            data: {
                config: nodeConfig.data.config,
                label: nodeConfig.data.label,
                inputs: nodeConfig.data.inputs,
                outputs: nodeConfig.data.outputs,
                inputValues: {}
            },
            position: position
        }
        setNodes((prev) => {
            return [...prev, newNode]
        })
        connectToPreviousNode(newNode, lastNode)
    }

    function connectToPreviousNode(current, previous) {
        if (!previous) return
        if (!current) return
        const edgeId = v4()
        const newEdge = {
            id: edgeId,
            source: previous.id,
            target: current.id
        }
        setEdges((e) => {
            return [...e, newEdge]
        })
    }
    // Function to handle when a node drag stops
    // @ts-ignore
    const onNodeDragStop = useCallback((event, node) => {
        // Get the new y position
        const newYPosition = node.position.y
        // Set the node's position with the original x and the new y
        setNodes((nds) =>
            nds.map((nd) =>
                nd.id === node.id
                    ? { ...nd, position: { x: 0, y: newYPosition } }
                    : nd
            )
        )
    }, [])

    const triggerNodes = nodes.filter((node) => node.type === 'input')
    const isTriggerAdded = triggerNodes.length > 0
    const [nodeInputValues, setNodeInputValues] = useState({})

    const nodeId = selectedNode?.id
    const nodeDataId = selectedNode?.data?.config?.id
    const allNodes = [...availableActions, ...availableTriggers]
    const selectedDataNode = allNodes.find(node => node?.data?.config?.id === nodeDataId)

    const inputs = selectedDataNode?.data?.inputs ?? []
    const outputs = selectedDataNode?.data?.outputs ?? []

    useEffect(() => {
        setNodeInputValues(selectedNode?.data?.inputValues || {})
    }, [selectedNode])

    function updateWorkFlowName(name) {
        setUpdatingName(true)
        updateWorkflow(workflow._id, {
            name
        }).then((response) => {
            setEditingName(false)
        }).finally(() => {
            setUpdatingName(false)
        })
    }

    return (
        <div className='flex w-full h-full flex-col'>
            <div className='flex gap-x-1 py-2 px-4 border-b border-border justify-between'>
                <div className='flex gap-x-2'>
                    <div>
                        <a href='/admin/workflows' className='flex gap-x-1 items-center justify-center'>
                            <ArrowLeftIcon size={18} />
                            <span>Back to Workflows</span>
                        </a>
                    </div>
                    <h1 className='text-2xl font-bold'>
                        {editingName && (
                            <div className='flex items-center justify-center gap-x-2'>
                                <input type='text' className='min-w-[300px]' value={workflowName}
                                    disabled={updatingName}
                                    onChange={(e) => {
                                        if (updatingName) return
                                        setWorkflowName(e.target.value)
                                    }} />
                                <button
                                    className='py-0.5 px-1.5 text-sm bg-blue-500 text-white rounded-md'
                                    disabled={updatingName}
                                    onClick={() => {
                                        updateWorkFlowName(workflowName)
                                    }}
                                >
                                    Update
                                </button>
                                <button
                                    className='py-0.5 px-1.5 text-sm bg-red-500 text-white rounded-md'
                                    disabled={updatingName}
                                    onClick={() => {
                                        setWorkflowName(workflow?.name)
                                        setEditingName(false)
                                    }}
                                >
                                    cancel
                                </button>
                            </div>
                        )}
                        {!editingName && <div className='flex items-center justify-center gap-x-2'>
                            <div>
                                {workflowName}
                            </div>
                            <div>
                                <button
                                    className='py-0.5 px-1.5 text-sm bg-blue-500 text-white rounded-md'
                                    onClick={() => {
                                        setEditingName(true)
                                    }}>Edit</button>
                            </div>
                        </div>}
                    </h1>
                </div>
                <div className='flex flex-row gap-x-1'>
                    <button
                        className={`px-2 py-1 bg-blue-600 text-white rounded`}
                        onClick={saveDraft}
                        disabled={publishing || savingDraft}
                    >
                        {savingDraft ? 'Saving...' : 'Save Draft'}
                    </button>

                    {nodes.length > 1 && <button
                        className={`px-2 py-1 bg-green-600 text-white rounded`}
                        onClick={saveAndPublish}
                        disabled={publishing || savingDraft}
                    >
                        {publishing ? 'Publishing...' : 'Save & Publish'}
                    </button>}
                </div>
            </div>
            <ReactFlowProvider>
                <div className='flex flex-row gap-2 w-full h-full divide-x'>
                    <ReactFlow
                        className='flex-1 flex w-full h-full'
                        nodes={nodes}
                        edges={edges}
                        snapToGrid
                        nodesDraggable={false}
                        onSelect={(e) => console.log('selected', e)}
                        onSelectionChange={(e) => {
                            const nodes = e.nodes
                            const newSelected =
                                nodes.length > 0 ? nodes[0] : null
                            if (newSelected) {
                                if (newSelected?.id !== selectedNode?.id) {
                                    setSelectedNode(newSelected)
                                }
                            } else {
                                setSelectedNode(null)
                            }
                        }}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onNodeDragStop={onNodeDragStop}
                        defaultNodes={[]}
                        defaultEdges={[]}
                        fitView
                    >
                        <Background
                            id='1'
                            gap={10}
                            color='#f1f1f1'
                            variant={BackgroundVariant.Lines}
                        />

                        <Background
                            id='2'
                            gap={100}
                            color='#ccc'
                            variant={BackgroundVariant.Lines}
                        />
                        <MiniMap />
                        <Controls />
                    </ReactFlow>
                    <div className='h-full min-w-[350px]'>
                        {selectedNode && (
                            <div className='relative h-full'>
                                <div>
                                    <div className='py-1 px-1.5'>
                                        <div>
                                            <ComponentItem
                                                item={selectedNode}
                                            />
                                        </div>
                                        {inputs.length > 0 && (
                                            <div className='border-t border-border my-1'></div>
                                        )}
                                        <div>
                                            {inputs
                                                // @ts-ignore
                                                .map((input, index) => {
                                                    const id = `${nodeId}-input-${input.key}-${index}`
                                                    const inputType = input.type
                                                    if (inputType === 'number') {
                                                        return (
                                                            <div key={id}>
                                                                <div>
                                                                    {input.label}
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type='number'
                                                                        value={nodeInputValues[input.key] || ''}
                                                                        onChange={(e) => setNodeInputValues(prev => ({
                                                                            ...prev,
                                                                            [input.key]: e.target.value
                                                                        }))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (inputType === 'select') {
                                                        return (
                                                            <div key={id}>
                                                                <div>
                                                                    {input.label}
                                                                </div>
                                                                <div>
                                                                    <select
                                                                        className='w-full'
                                                                        value={nodeInputValues[input.key] || ''}
                                                                        onChange={(e) => {
                                                                            console.log('e', e.target.value)
                                                                            setNodeInputValues(prev => ({
                                                                                ...prev,
                                                                                [input.key]: e.target.value
                                                                            }))
                                                                        }}
                                                                    >
                                                                        {input.options.map((option) => {
                                                                            return (
                                                                                <option key={option.value} value={option.value}>
                                                                                    {option.label}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    else if (inputType === 'email-template-select') {
                                                        return (
                                                            <div key={id}>
                                                                <div>
                                                                    {input.label}
                                                                </div>
                                                                <div>
                                                                    <EmailTemplateSelect
                                                                        value={nodeInputValues[input.key] || ''}
                                                                        changeSubject={(subject) => {
                                                                            setNodeInputValues(prev => ({
                                                                                ...prev,
                                                                                subject
                                                                            }))
                                                                        }}
                                                                        onChange={(newValue) => setNodeInputValues(prev => ({
                                                                            ...prev,
                                                                            [input.key]: newValue
                                                                        }))}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div key={id}>
                                                            <div>
                                                                {input.label}
                                                            </div>
                                                            <div>
                                                                <PlaceholderInput
                                                                    nodes={nodes}
                                                                    value={nodeInputValues[input.key] || ''}
                                                                    onChange={(newValue) => setNodeInputValues(prev => ({
                                                                        ...prev,
                                                                        [input.key]: newValue
                                                                    }))}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                        <div>
                                            {// @ts-ignore
                                                inputs.length > 0 && (
                                                    <button
                                                        className='mt-2 px-2 py-1 bg-yellow-600 text-white rounded'
                                                        onClick={() => {
                                                            selectedNode.data.inputValues =
                                                                nodeInputValues
                                                            alert(
                                                                'Settings updated'
                                                            )
                                                        }}
                                                    >
                                                        Update Settings
                                                    </button>
                                                )}
                                        </div>
                                        <AvailableOutputVariables outputs={outputs} />
                                    </div>
                                </div>
                                <div className='absolute bottom-0 left-0 right-0 w-full py-1.5 bg-gray-100 border border-border px-2'>
                                    <div className='flex gap-x-2'>
                                        <CustomButton
                                            className='flex-1 h-10 bg-red-500 text-white flex items-center justify-center'
                                            onClick={() => {
                                                if (!window.confirm('Are you sure you want to delete this node?')) return
                                                const newNodes = nodes.filter((node) => node.id !== selectedNode.id)
                                                const newEdges = edges.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id)
                                                setNodes(newNodes)
                                                setEdges(newEdges)
                                                setSelectedNode(null)
                                                setTimeout((nodes) => {
                                                    const currentIndex = nodes.findIndex(node => node.id === selectedNode.id)
                                                    const previousNode = currentIndex > 0 ? nodes[currentIndex - 1] : null
                                                    const nextNode = nodes.length > currentIndex + 1 ? nodes[currentIndex + 1] : null
                                                    connectToPreviousNode(nextNode, previousNode)
                                                }, 100, nodes);
                                                setTimeout(() => {
                                                    resetNodesPositionInColumn(newNodes)
                                                }, 200);
                                            }}
                                        >
                                            <TrashIcon size={18} />  Delete
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!selectedNode && (
                            <div className='px-2'>
                                <div>
                                    <div>
                                        <div>Triggers</div>
                                        {isTriggerAdded && (
                                            <div className='text-red-400 whitespace-normal my-2'>
                                                You can only have one trigger, create new workflow to add more automation
                                            </div>
                                        )}
                                        {!isTriggerAdded && (
                                            <div className='flex flex-col gap-y-1'>
                                                {availableTriggers.map(
                                                    (trigger) => {
                                                        return (
                                                            <div
                                                                key={trigger.id}
                                                                className='cursor-pointer'
                                                                onClick={() => {
                                                                    addNewNode?.(
                                                                        trigger
                                                                    )
                                                                }}
                                                            >
                                                                <ComponentItem
                                                                    item={trigger}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {isTriggerAdded && (
                                        <div>
                                            <div>Actions</div>
                                            <div className='flex flex-col gap-y-1'>
                                                {availableActions.map(
                                                    (action) => {
                                                        return (
                                                            <div
                                                                key={action.id}
                                                                className='cursor-pointer'
                                                                onClick={() => {
                                                                    addNewNode?.(
                                                                        action
                                                                    )
                                                                }}
                                                            >
                                                                <ComponentItem
                                                                    item={
                                                                        action
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ReactFlowProvider >
        </div >
    )
}

function ComponentItem(props) {
    const item = props.item
    return (
        <div
            key={item.id}
            className='px-3 py-1 flex gap-x-2 items-center cursor-pointer'
            style={{
                backgroundColor: item.data?.config?.color,
            }}
        >
            <div>
                <img src={item.data?.config?.icon} alt='' />
            </div>
            <div>{item.data?.config?.label}</div>
        </div>
    )
}

function AvailableOutputVariables({ outputs }) {
    if (!outputs) return <div>
        No outputs variables
    </div>
    return <div className='w-full overflow-x-auto max-w-full border-t pt-2 border-border my-3'>
        <div className='text-gray-400 py-1'>Available output Variables ({outputs.length})</div>
        <div className='flex flex-col gap-y-1'>
            {outputs.map((output, index) => {
                return (
                    <div key={output.key + "-" + index}>
                        {output.label} <span className='text-xs text-gray-400'>({output.key})</span>
                    </div>
                )
            })}
        </div>
    </div>
}


const PlaceholderInput = ({ nodes, value = '', onChange }) => {
    const [inputValue, setInputValue] = useState(value);
    const [suggestions, setSuggestions] = useState([]);
    const [availableVariables, setAvailableVariables] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1); // Tracks the selected suggestion index

    // Extract trigger node variables without full path
    useEffect(() => {
        const triggerNode = nodes.find(node => node.type === "input");
        if (triggerNode && triggerNode.data.outputs) {
            const triggerVariables = triggerNode.data.outputs.map(output => ({
                displayName: output.label,
                uniqueName: output.key
            }));
            setAvailableVariables(triggerVariables);
        }
    }, [nodes]);

    // Update input value when props change
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    // Handle input change and show relevant suggestions
    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        onChange(newValue);  // Pass updated value to parent component

        const lastPart = newValue.split(' ').pop();
        if (lastPart.startsWith('{{')) {
            const query = lastPart.slice(2).toLowerCase();
            const filteredSuggestions = availableVariables.filter(({ displayName }) =>
                displayName.toLowerCase().includes(query)
            );
            setSuggestions(filteredSuggestions);
            setActiveSuggestionIndex(-1); // Reset active suggestion when suggestions change
        } else {
            setSuggestions([]);
        }
    };

    // Handle keyboard navigation
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            // Move selection down
            setActiveSuggestionIndex((prevIndex) =>
                prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === 'ArrowUp') {
            // Move selection up
            setActiveSuggestionIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
            );
        } else if (event.key === 'Enter' && activeSuggestionIndex >= 0) {
            // Select the currently highlighted suggestion
            handleSuggestionClick(suggestions[activeSuggestionIndex]);
            event.preventDefault(); // Prevent form submission if inside a form
        } else if (event.key === 'Escape') {
            // Close suggestions on Escape key
            setSuggestions([]);
        }
    };

    // Handle suggestion selection
    const handleSuggestionClick = (suggestion) => {
        const lastSpaceIndex = inputValue.lastIndexOf(' ');
        const newValue =
            inputValue.substring(0, lastSpaceIndex + 1) + `{{${suggestion.uniqueName}}} `;
        setInputValue(newValue);
        setSuggestions([]);
        onChange(newValue);  // Pass updated value to parent component
    };

    return (
        <div className="relative">
            <textarea
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Add keyboard navigation support
                className="border p-2 w-full"
                placeholder="Type '{{' to see available variables"
            />
            {suggestions.length > 0 && (
                <ul className="absolute bg-white border mt-1 w-full max-h-60 overflow-auto z-50 shadow-lg">
                    {suggestions.map(({ displayName, uniqueName }, index) => (
                        <li
                            key={uniqueName}
                            className={`p-2 cursor-pointer hover:bg-gray-200 ${index === activeSuggestionIndex ? 'bg-gray-200' : ''
                                }`}
                            onClick={() => handleSuggestionClick({ displayName, uniqueName })}
                        >
                            {displayName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};



function EmailTemplateSelect({ value, onChange, changeSubject }) {
    const [emailTemplates, setEmailTemplates] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getEmailTemplates().then((response) => {
            setEmailTemplates(response)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loading) return <div>Loading...</div>

    return (
        <div>
            <select
                value={value}
                onChange={(e) => {
                    const templateId = e.target.value
                    onChange(templateId)
                    const selectedTemplate = emailTemplates.find(item => item._id === templateId)
                    if (selectedTemplate) {
                        changeSubject(selectedTemplate.subject)
                    }
                }}
            >
                <option value=''>Select Email Template</option>
                {emailTemplates.map((item) => {
                    return (
                        <option key={item._id} value={item._id}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}