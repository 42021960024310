// @ts-nocheck
/* eslint-disable */
import React from "react";
import { TrendingUp } from "lucide-react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ShadcnComponents/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ShadcnComponents/chart";
import { Tooltip } from "antd";

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Safari",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Firefox",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Edge",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
};

const CustomYAxisTick = ({ x, y, payload }) => {
  return (
    <Tooltip title={payload.value}>
      <g transform={`translate(${x},${y})`}>
        <text
        fill="#666"
        textAnchor="end"
        dy={0} // Center the text vertically
        style={{ marginBottom: '10px', dominantBaseline: 'middle' }} // Center vertically
      >
        {payload.value}
      </text>
    </g>
    </Tooltip>
  );
};
export function BarListShadCN({
  data,
  XAxisDataKey,
  XAxisTickFormatter,
  dataKey1,
  dataKey2,
  interval,
  angle,
  textAnchor,
  height,
  tickStyle,
  tickMargin = 10,
  ChartContainerProps,
  title,
  description,
  XAxisType,
  footer,
}) {
  return (
    <Card className="h-full bg-white">
      <div className="flex justify-between p-4 items-center">
        <div className="text-2xl font-semibold">{title}</div>
      </div>
      <CardContent>
        <ChartContainer config={chartConfig} {...ChartContainerProps}>
        <ResponsiveContainer width="100%" height={300}>
        <BarChart
              data={data}
              layout="vertical"
              margin={{
                left: 50,
                top: 20,    
                bottom: 20, 
              }}
            >
            <CartesianGrid horizontal={false} />
            <YAxis
              dataKey={XAxisDataKey}
              type={"category"}
              interval={interval}
              tickLine={false}
              tickMargin={tickMargin}
              axisLine={false}
              tick={<CustomYAxisTick />}
              
            />
            <XAxis dataKey={dataKey2} type={"number"} hide />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <Bar dataKey={dataKey2} layout="vertical" radius={4} />
          </BarChart>
        </ResponsiveContainer>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        {footer}
      </CardFooter>
    </Card>
  );
}
