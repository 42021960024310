import { useQuery } from '@tanstack/react-query'
import { getWorkflows, getWorkflow, deactivateWorkflow, activateWorkflow, getWorkflowActionsLogs, getWorkflowLogs, updateWorkflow, publishWorkflow, saveWorkflowDraft, createNewWorkflow, getWorkflowActions, getWorkflowTriggers } from 'store/actions/workflowActions'

export const useGetWorkflows = ({
  projectId,
  isCorporate
}) => {
  const { data, isLoading, refetch } = useQuery(
    ['get-custom-workflows'],
    () => getWorkflows(!isCorporate ? projectId : ''),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false }
  )
  return { workflows: data ?? null, isLoading, refetch }
}

export const useWorkflows = () => {
  return {
    getWorkflows,
    getWorkflow,
    getWorkflowLogs,
    getWorkflowActionsLogs,
    saveWorkflowDraft,
    activateWorkflow,
    deactivateWorkflow,
    publishWorkflow,
    createWorkflow: createNewWorkflow,
    getTriggers: getWorkflowTriggers,
    updateWorkflow,
    getActions: getWorkflowActions
  }
}

export default useWorkflows
