/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import useGetSalesReps from '../../../hooks/useGetSalesReps'
import { useGetContactLists } from '../../../hooks/useGetContactLists'
import useBrokerageCompanies from 'hooks/useBrokerageCompanies'
import useReferralAgents from 'hooks/useReferralAgents'
import useLeadSource from 'hooks/useLeadSource'
import useBrokerScore from 'hooks/useBrokerScore'
import { getBrokerageCompanies } from 'store/actions/usersActions'
import moment from 'moment';
import { DateRangePicker } from "rsuite";

const BrokerFilterList = ({ selectedState, setSelectedState }) => {
  const [salesRepList, setSalesRepList] = useState([])
  const [list, setList] = useState([])
  const [companies, setCompany] = useState([])
  const { salesReps, refetch } = useGetSalesReps()
  const { contactsLists, getContacts } = useGetContactLists()
  const { referralAgents } = useReferralAgents()
  const { loading: isLeadSourceLoading, formattedLeadsources: leadSources } =
    useLeadSource()
  const { loading: isBrokerScoreLoading, formattedScores: brokerScores } =
    useBrokerScore()

  useEffect(() => {
    getBrokerageCompanies().then((res) => {
      let mapped = res?.values?.map((company, index) => {
        return { id: index, name: company }
        });
        setCompany(mapped);
      });
    }, []);

  useEffect(() => {
    if (!contactsLists.length) getContacts()
  }, [])

  useEffect(() => {
    const arr = []
    contactsLists.forEach((el) => {
      if (!arr.find((item) => el.title === item.name)) {
        arr.push({ id: el._id, name: el.title })
      }
    })
    setList(arr)
  }, [contactsLists])

  useEffect(() => {
    if (!salesReps.length) {
      refetch()
      return
    }
    const salesRepList = salesReps.map((el) => ({
      id: el._id,
      name: `${el.firstName} ${el.lastName}`
    }))
    setSalesRepList(salesRepList)
  }, [salesReps])

  return (
    <>
      <Select
        placeholder='Broker Score'
        loading={isBrokerScoreLoading}
        value={selectedState?.rating}
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            rating: value
          })
        }
      >
        <Select.Option label='All' value={''}>
          All
        </Select.Option>
        {brokerScores?.map?.((el) => (
          <Select.Option key={el.value} label={el.label} value={el.value}>
            {el.label}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder='Brokerage Company'
        value={selectedState?.brokerageCompany}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            brokerageCompany: value
          })
        }
      >
        <Select.Option label='All' value={''}>
          All
        </Select.Option>
        {companies?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.name}>
            {el?.name}
          </Select.Option>
        ))}
      </Select>
     
      <Select
        placeholder="Contacted Status"
        value={selectedState.contacted_status}
        onChange={(value) => {
          setSelectedState({
            ...selectedState,
            contacted_status: value,
          });
        }}
      >
        <Select.Option label="All" value="">
          All
        </Select.Option>
        <Select.Option label="Contacted" value="contacted">
          Contacted
        </Select.Option>
        <Select.Option label="Not Contacted" value="not_contacted">
          Not Contacted
        </Select.Option>
      </Select>
      <DateRangePicker
        placeholder="Last Contacted At"
        showOneCalendar
        hoverRange={(date)=>{}}
        ranges={[]}
        value={[
          selectedState.contactedStartDate ?
          moment(selectedState?.contactedStartDate).isValid() ? moment(selectedState?.contactedStartDate).toDate() : '' : '',
          selectedState.contactedEndDate ? 
          moment(selectedState?.contactedEndDate).isValid() ? moment(selectedState?.contactedEndDate).toDate() : '' : ''
        ]}
        onChange={(value) => {
          if(value === null){
            setSelectedState({
              ...selectedState,
              contactedStartDate: '',
              contactedEndDate: ''
            })
          } else {
          setSelectedState({
            ...selectedState,
            contactedStartDate: moment(value?.[0]).toISOString(),
            contactedEndDate: moment(value?.[1]).toISOString()
          })
        }
        }}
        className="!border-black"
        cleanable={true}
      />
      <Select
        showSearch={true}
        placeholder='Lists'
        value={selectedState?.lists}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            lists: value
          })
        }
      >
        <Select.Option label='All' value={''}>
          All
        </Select.Option>
        {list?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder='Sales Rep Owner'
        value={selectedState?.salesRepOwner}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            salesRepOwner: value
          })
        }
      >
        <Select.Option label='All' value={''}>
          All
        </Select.Option>
        <Select.Option label="None" value={"none"}>
          None
        </Select.Option>
        {salesRepList?.map((el) => (
          <Select.Option key={el.id} label={el.name} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        placeholder='Referral Agent'
        value={selectedState?.referralAgent}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            referralAgent: value
          })
        }
      >
        <Select.Option label='All' value={''}>
          All
        </Select.Option>
        {referralAgents?.map?.((referralAgent) => (
          <Select.Option
            key={referralAgent._id}
            label={referralAgent.firstName}
            value={referralAgent._id}
          >
            {`${referralAgent.firstName} ${referralAgent.lastName}`}
          </Select.Option>
        ))}
      </Select>
      <Select
        showSearch={true}
        loading={isLeadSourceLoading}
        placeholder='Lead Source'
        value={selectedState?.leadSource}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        onChange={(value) =>
          setSelectedState({
            ...selectedState,
            leadSource: value
          })
        }
      >
        <Select.Option label='All' value={''}>
          All
        </Select.Option>
        {leadSources?.map?.((el) => (
          <Select.Option key={el.value} label={el.label} value={el.value}>
            {el.label}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default BrokerFilterList
