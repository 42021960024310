// @ts-nocheck
/* eslint-disable */
import { Pie, PieChart } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ShadcnComponents/card";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ShadcnComponents/chart";
import React from "react";

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Safari",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Firefox",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Edge",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
};

export function PieChartShadCN({
  data,
  dataKey,
  nameKey,
  footer,
  title,
  description,
  hideLabel = false,
  legend = true,
  innerRadius,
  strokeWidth,
  label = true,
  children,
}) {
  return (
    <Card className="flex flex-col bg-white h-full">
      <CardHeader className="items-center pb-0">
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="flex-1">
        <ChartContainer
          config={chartConfig}
          className="aspect-square w-full h-[300px]"
        >
          <PieChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <Pie 
              data={data} 
              dataKey={dataKey} 
              nameKey={nameKey}
              innerRadius={innerRadius}
              strokeWidth={strokeWidth}
              outerRadius="80%"
              label={{
                position: 'outside',
                offset: 15,
                fill: 'hsl(var(--foreground))',
              }}
              labelLine={{
                stroke: 'hsl(var(--foreground))',
                strokeWidth: 1,
              }}
            >
              {children}
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">{footer}</CardFooter>
    </Card>
  );
}
