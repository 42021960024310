// @ts-nocheck
import standardStyle from 'assets/css/standardStyle'
import { FadedAnimatedDiv, LottieWrapper, PageHeader } from 'components'
import React, { memo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import CreateEmailTemplateSlider from './CreateEmailTemplate'
import { createOrUpdateEmailTemplates, deleteEmailTemplates, getEmailTemplates, syncContactsToSendGrid } from 'store/actions/emailTemplateActions'
import DeleteModal from 'components/DeleteModal'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
import EmailTemplatePreview from './TemplatePreview'
import { CopyIcon, EyeIcon, Pen, Trash } from 'lucide-react'
import CustomButton from 'components/CustomButton'
import { Oval } from 'react-loader-spinner'
import CustomInput from 'components/CustomInput'
import CustomModal from 'ui/customModal'

const Card = ({ title, value }) => (
  <div className=''>
    <div
      className='bg-white rounded'
      style={{ fontFamily: standardStyle.fonts.sfpro }}
    >
      <div className='flex flex-col space-y-2 p-6'>
        <div className='text-xs text-softBlack_70 font-medium uppercase'>
          {title}
        </div>
        <div className='text-xl text-softBlack font-bold'>{value}</div>
      </div>
    </div>
  </div>
)

const EditEmailTemplateModal = memo(({ showModal, onCancel, onOk, emailTemplate, setEmailTemplate, isLoading }) => {
  return (
    <CustomModal
      showModal={showModal}
      onCancel={onCancel}
      onOk={onOk}
    >
      <div className='min-w-[95%] md:min-w-[400px] min-h-[90%] md:min-h-[200px] flex flex-col gap-4'>
        <h2 className='text-2xl mb-0'>Edit Email Template</h2>
        <CustomInput
          label='Email template Name *'
          placeholder='Enter email template name'
          labelClassName='text-xs mb-[6px]'
          value={emailTemplate.name ?? ''}
          onChange={(e) => {
            setEmailTemplate({
              ...emailTemplate,
              name: e.target.value,
              unlayerTemplateName: e.target.value
            })
          }}
          type='text'
          disabled={isLoading}
          containerClasses='w-full'
        />
        <CustomInput
          label='Email Subject *'
          placeholder='Enter Subject'
          labelClassName='text-xs mb-[6px]'
          value={emailTemplate.subject ?? ''}
          onChange={(e) => {
            setEmailTemplate({
              ...emailTemplate,
              subject: e.target.value
            })
          }}
          type='text'
          disabled={isLoading}
          containerClasses='w-full'
        />
        <div className='flex-1 w-full mt-2 flex items-end justify-center md:justify-end gap-3'>
          <CustomButton variant='outline' handleClick={onCancel} disabled={isLoading}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              Cancel
            </span>
          </CustomButton>
          <CustomButton handleClick={onOk} disabled={(!emailTemplate.name || emailTemplate.name.trim() === '') || isLoading}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              {isLoading && <Oval height='18' width='18' color='white' />}
              Save
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  )
})

const EmailCampaignBuilderPage = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [templates, setTemplates] = useState([])
  const [isSliderOpen, setIsSliderOpen] = useState(false)
  const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [synchingContacts, setSynchingContacts] = useState(false)
  const [deleteTemplateId, setDeleteTemplateId] = useState()
  const [duplicatingTemplates, setDuplicatingTemplates] = useState({})
  const [newTemplate] = useState({
    name: '',
    subject: '',
    unlayerProjectId: 174034,
    unlayerTemplateId: Date.now(),
    unlayerTemplateName: '',
    unlayerConfig: {}
  })
  const [emailTemplate, setEmailTemplate] = useState({
    name: '',
    subject: ''
  })
  const [showEditEmailTemplateModal, setShowEditEmailTemplateModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const getAllTemplates = () => {
    getEmailTemplates().then((response) => {
      setTemplates(response)
      setIsLoading(false)
    }).catch((error) => console.log(error))
  }

  useEffect(() => {
    getAllTemplates()
  }, [])

  const createTemplate = (name) => {
    setIsSliderOpen(false)
    createOrUpdateEmailTemplates({
      ...newTemplate,
      name: name,
      subject: name,
      unlayerTemplateName: name
    })
      .then((res) => {
        history.push(
          `/admin/email-builder/edit/${res.id}`
        )
      })
      .catch((error) => console.log(error))
  }

  const duplicateTemplate = (template) => {
    const templateId = template.id
    const isDuplicating = duplicatingTemplates[templateId]
    if (isDuplicating) return
    setDuplicatingTemplates({
      ...duplicatingTemplates,
      [templateId]: true
    })
    createOrUpdateEmailTemplates(
      {
        _id: templateId
      }, true)
      .then(() =>
        getAllTemplates()
      )
      .catch((error) => console.log(error)).finally(() => {
        setDuplicatingTemplates((e) => {
          return {
            ...e,
            [templateId]: false
          }
        })
      })
  }

  const deleteTemplate = () => {
    setIsLoading(true)
    deleteEmailTemplates(deleteTemplateId)
      .then(() => getAllTemplates())
      .catch((error) => console.log(error))
    setShowDeleteModal(false)
  }

  const RightContent = () => (
    <div className='flex gap-x-2 flex-row'>
      <CustomButton handleClick={() => setIsSliderOpen(true)}>
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          Create Template
        </span>
      </CustomButton>
      <CustomButton
        handleClick={async () => {
          setSynchingContacts(true)
          syncContactsToSendGrid().finally(() => {
            setSynchingContacts(false)
          })
        }}
        disabled={synchingContacts}
      >
        <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
          Sync SendGrid Contacts
        </span>
      </CustomButton>
    </div>
  )

  const handleUpdateEmailTemplate = () => {
    setIsEditing(true)
    createOrUpdateEmailTemplates({
      ...emailTemplate
    })
      .then((res) => {
        setShowEditEmailTemplateModal(false)
        setEmailTemplate({
          name: ''
        })
        setIsEditing(false)
        getAllTemplates()
      })
      .catch((error) => {
        console.log(error)
        setShowEditEmailTemplateModal(false)
        setEmailTemplate({
          name: ''
        })
        setIsEditing(false)
      })
  }

  return (
    <>
      <PageHeader
        title='Email Campaigns Builder'
        rightContent={<RightContent />}
      />
      <div className='px-4 sm:px-6 lg:px-8 mt-4'>
        <div className='grid grid-cols-6 gap-4 pt-4'>
          <Card title={'Templates'} value={templates.length} />
        </div>
        <div className='mt-8 flow-root bg-white rounded px-6'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              {
                isLoading
                  ? <>
                    <FadedAnimatedDiv
                      key='loadingTable'
                      className='w-full h-full flex items-center justify-center my-10 flex-col'
                    >
                      <LottieWrapper
                        animation={tableLoading}
                        loop
                        className='h-16 w-16'
                      />
                      <span className='text-base'>loading...</span>
                    </FadedAnimatedDiv>
                  </>
                  : templates.length
                    ? <table className='min-w-full divide-y divide-gray-300'>
                      <thead>
                        <tr>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Template Name
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Email Subject
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200'>
                        {templates?.map((template) => (
                          <tr key={template.id}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {template.name}
                            </td>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {template?.subject ?? '-'}
                            </td>
                            <td className='whitespace-nowrap py-4 text-sm flex justify-end gap-3 text-gray-900 sm:pl-0'>
                              <CustomButton
                                className='w-fit'
                                handleClick={() =>
                                  history.push(
                                    `/admin/email-builder/edit/${template.id}`
                                  )
                                }
                              >
                                <span className='font-semibold px-6 py-2 rounded text-base flex items-center gap-2'>
                                  Use Template
                                </span>
                              </CustomButton>
                              <button
                                className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center gap-2 border border-black'
                                onClick={() => {
                                  setSelectedTemplateForPreview(template)
                                }}
                              >
                                <EyeIcon size={16} />
                                <span>
                                  Preview
                                </span>
                              </button>
                              <button
                                className='text-white text-center text-base font-medium py-2 px-6 rounded flex items-center border border-black bg-black gap-2'
                                onClick={() => {
                                  setShowEditEmailTemplateModal(true)
                                  setEmailTemplate({
                                    _id: template.id,
                                    name: template.name,
                                    subject: template.subject,
                                    unlayerTemplateName: template.name
                                  })
                                }}
                              >
                                <Pen size={16} />
                                <span>
                                  Edit
                                </span>
                              </button>
                              <button
                                className='gap-x-1 text-softBlack text-center text-base font-medium py-2 px-4 rounded flex items-center text-white bg-red-600'
                                onClick={() => {
                                  setDeleteTemplateId(template.id)
                                  setShowDeleteModal(true)
                                }}
                              >
                                <Trash size={16}></Trash>
                                Delete
                              </button>
                              <button
                                disabled={duplicatingTemplates[template.id]}
                                className='gap-x-1 text-softBlack text-center text-base font-medium py-2 px-4 rounded flex items-center text-white  bg-blue-600'
                                onClick={() => {
                                  if (duplicatingTemplates[template.id]) return
                                  duplicateTemplate(template)
                                }}
                              >
                                {duplicatingTemplates[template.id] ? <Oval height='18' width='18' color='white' /> : <CopyIcon size={16}></CopyIcon>}
                                {duplicatingTemplates[template.id] ? 'Duplicating...' : 'Duplicate'}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    : <h1 className='text-center text-xl py-6 mb-0'>Templates not found.</h1>
              }
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        title='Are you sure?'
        description='Do you want to delete?'
        confirmButtonTitle='Delete Template'
        cancelButtonTitle='Cancel'
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        onConfirm={deleteTemplate}
      />
      <CreateEmailTemplateSlider
        onClose={() => setIsSliderOpen(false)}
        onSubmit={(name) => createTemplate(name)}
        open={isSliderOpen}
      />
      {selectedTemplateForPreview && (
        <EmailTemplatePreview
          selectedTemplate={selectedTemplateForPreview}
          onClose={() => setSelectedTemplateForPreview(null)}
          open={selectedTemplateForPreview}
        />
      )}
      <EditEmailTemplateModal
        showModal={showEditEmailTemplateModal}
        onCancel={() => {
          setShowEditEmailTemplateModal(false)
          setEmailTemplate({
            name: '',
            subject: ''
          })
        }}
        onOk={handleUpdateEmailTemplate}
        emailTemplate={emailTemplate}
        setEmailTemplate={setEmailTemplate}
        isLoading={isEditing}
      />
    </>
  )
}

export default EmailCampaignBuilderPage
