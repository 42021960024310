/* eslint-disable */
// @ts-nocheck
import CustomInput from "components/CustomInput"
import CustomSelect from "../../../pages/reservation/NewReservation/components/CustomSelect"
import useGetFilterUsers from "hooks/useGetFilterUsers"
import { Plus, Trash2, UserPlus } from "lucide-react"
import { Select } from "antd"
import moment from "moment-timezone"
import { SectionWrapper } from "pages/reservation/NewReservation/NewVIPLotReservation"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { IoMdClose } from "react-icons/io"
import useGetProjectDepositOptions from "hooks/useGetProjectDepositOptions"
import { ReactComponent as DownIcon } from "assets/icons/down.svg"
import useGetProjectUnits from "hooks/useGetProjectUnits"
import { useSelector } from "react-redux"
import { Drawer, makeStyles } from "@material-ui/core"
import { isMobile } from "react-device-detect"
import { createOrUpdateOffer } from "store/actions/offerActions"
import CustomButton from "components/CustomButton"
import { Oval } from "react-loader-spinner"
import { ADMIN_OFFERS } from "routes"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card"
import { formatMoney } from "utils"
import { Button } from "components/ShadcnComponents/button"
import { Textarea } from "components/ShadcnComponents/textarea"
import { Input } from "components/ShadcnComponents/input"
import Checkbox from "components/Checkbox"
import useGetProjectParkings from "hooks/useGetProjectParkings"
import useGetProjectUpgradeAndOptions from "hooks/useGetProjectUpgradeAndOptions"
import { CreateOfferContext } from "pages/offer/context/CreateOfferContext"
import { getProjects } from "store/actions/projectActions"
import PhoneInput from "react-phone-number-input"

export const OFFER_STATUS_OPTIONS = [
  { value: "SoldFirm", label: "Sold - Firm" },
  { value: "Signed", label: "Signed" },
  { value: "OfferSent", label: "Offer Sent" },
  { value: "OfferCreated", label: "Offer Created" },
  { value: "OfferAccepted", label: "Offer Accepted" },
  { value: "Paid", label: "Paid" },
  { value: "SevenDaysRescission", label: "Seven Days Rescission" },
  { value: "OfferRejected", label: "Offer Rejected" },
  { value: "ContractCancelled", label: "Offer Cancelled" },
]

const PAYMENT_METHOD = [
  { value: "wire", label: "Wire" },
  { value: "check", label: "Check" },
  { value: "cash", label: "Cash" },
  { value: "card", label: "Card" },
]

const BROKER = [
  { value: "barry-bonds", label: "Barry Bonds" },
  { value: "john-doe", label: "John Doe" },
  { value: "jane-smith", label: "Jane Smith" },
]
const SUBJECT_TYPE = [
  { value: "financing", label: "Financing" },
  { value: "marketing", label: "Marketing" },
  { value: "sales", label: "Sales" },
  { value: "operations", label: "Operations" },
]

const combineArray = (...arrays) => {
  const allObjects = arrays.flat() // Combine all arrays
  const uniqueObjects = Array.from(
    new Map(allObjects.map((obj) => [obj._id, obj])).values()
  )
  return uniqueObjects
}

const QuickForm = ({ defaultSelectedUnit }) => {
  const [purchaseDate, setPurchaseDate] = useState(null)
  const [selectedPrimaryBuyer, setSelectedPrimaryBuyer] = useState(null)
  const [selectSecondaryBuyer, setSelectSecondaryBuyer] = useState(null)
  const [selectedDepositOption, setSelectedDepositOption] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(defaultSelectedUnit)
  const [selectedOfferStatus, setSelectedOfferStatus] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const { filteredUsers, filtering } = useGetFilterUsers("", "Buyer", {})
  const [selectedParking, setSelectedParking] = useState([])
  const [selectedUpgrades, setSelectedUpgrades] = useState([])
  const [selectedCustomSection, setSelectedCustomSection] = useState([])
  const { selectedAdditionalOption, setSelectedAdditionalOption } =
    useContext(CreateOfferContext)
  const appProject = useSelector((state) => state.appReducer.appProject)
  const [purchaseAdditionalOption, setPurchaseAdditionalOption] = useState(true)
  const [customSectionOptions, setCustomSectionOptions] = useState([])
  const [subjects, setSubjects] = useState([
    { description: "", type: "", dueDate: "" },
  ])
  const [deposits, setDeposits] = useState([
    {
      number: 1,
      name: "",
      value: "",
      percentage: "",
      paymentMethodType: "",
      dueDate: "",
    },
  ])
  const [fintrac, setFintrac] = useState({
    dateInformationVerified: "",
    transactionPropertyAddress: "",
    thirdPart: false ? "No" : "Yes",
    businessRelationshipData: {
      residentialProperty: false,
      residentialPropertyForIncomePurposes: false,
      commercialProperty: false,
      commercialLand: false,
      others: false,
    },
    riskAssessment: {
      riskLevel: "Low",
      riskExplanation: "",
    },
    businessRelationship: [],
    thirdPartDescription: "",
    businessRelationshipExplanation: "",
    thirdPartRecord: {
      name: "",
      address: "",
      phoneNumber: "",
      birthday: "",
      occupation: "",
    },
  })
  console.log("fintrac: ", fintrac)
  const [priority, setPriority] = useState("low")
  const [checkboxes, setCheckboxes] = useState({
    residential: false,
    residentialIncome: false,
    commercial: false,
    commercialLand: false,
  })
  const [realtorData, setRealtorData] = useState({
    realtorName: "",
    brokerageName: "",
    brokerageAddress: "",
    brokeragePhone: "",
    email: "",
  })
  const [broker, setBroker] = useState("")
  const [newBroker, setNewBroker] = useState(false)
  const { parkings } = useGetProjectParkings()
  const { upgrades } = useGetProjectUpgradeAndOptions()
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [selectedSections, setSelectedSections] = useState({})

  const handleSectionChange = (sectionId, selectedValues) => {
    const optionsForSection = customSectionOptions.filter(
      (option) => option.type === sectionId
    )

    const selectedObjects = optionsForSection.filter((option) =>
      selectedValues.includes(option._id)
    )

    setSelectedSections((prevState) => ({
      ...prevState,
      [sectionId]: selectedObjects, // Store full objects
    }))
  }

  const CustomSectionData = useMemo(() => {
    return Object.values(selectedSections).flat()
  }, [selectedSections])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getProjects(appProject)
      .then((project) => {
        const { options } = project

        setCustomSectionOptions(options)
        setIsLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
      })
  }, [appProject])

  const handleCheckboxChange = (property) => {
    setFintrac((prevState) => {
      const newBusinessRelationship = {
        ...prevState.businessRelationshipData,
        [property]: !prevState.businessRelationshipData[property],
      }

      const updatedArray = Object.keys(newBusinessRelationship).filter(
        (key) => newBusinessRelationship[key]
      )

      return {
        ...prevState,
        businessRelationshipData: newBusinessRelationship,
        businessRelationship: updatedArray,
      }
    })
  }
  const handleRiskLevelChange = (level) => {
    setFintrac((prevState) => ({
      ...prevState,
      riskAssessment: {
        ...prevState.riskAssessment,
        riskLevel: level,
      },
    }))
  }
  const handleInputChange = (index, field, value) => {
    const updatedSubjects = [...subjects]
    updatedSubjects[index] = { ...updatedSubjects[index], [field]: value }
    setSubjects(updatedSubjects)
  }

  const addSubject = () => {
    setSubjects([...subjects, { description: "", type: "", dueDate: "" }])
  }

  const deleteSubject = (index) => {
    setSubjects((prevSubjects) => prevSubjects.filter((_, i) => i !== index))
  }

  const handleDepositChange = (index, field, value) => {
    const updatedDeposits = [...deposits]
    updatedDeposits[index][field] = value
    setDeposits(updatedDeposits)
  }

  const addDeposit = () => {
    const newDeposit = {
      number: deposits?.length + 1,
      name: "",
      value: "",
      percentage: "",
      paymentMethodType: "",
      dueDate: "",
    }
    setDeposits([...deposits, newDeposit])
  }

  const removeDeposit = (index) => {
    const updatedDeposits = deposits.filter((_, i) => i !== index)
    setDeposits(updatedDeposits)
  }

  const { loading, depositOptions } = useGetProjectDepositOptions()
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { units, unitsLoading } = useGetProjectUnits(projectId)

  const history = useHistory()

  const lotsInventoryOptions = useMemo(() => {
    return units.map((unit) => {
      return { label: `${unit.unitNumber}`, value: unit._id }
    })
  }, [units])

  const parkingOptions = useMemo(() => {
    return parkings.map((parking) => ({
      label: `${parking.name}`,
      value: parking._id,
    }))
  }, [parkings])

  const upgradesOptions = useMemo(() => {
    return upgrades.map((upgrade) => ({
      label: `${upgrade.name}`,
      value: upgrade._id,
    }))
  }, [upgrades])

  const selectedUnitData = useMemo(() => {
    if (!!selectedUnit) {
      return units.filter((unit) => unit._id === selectedUnit)?.[0] || null
    }
    return null
  }, [selectedUnit, units])

  const selectedDepositOptionData = useMemo(() => {
    if (!!selectedDepositOption) {
      return (
        depositOptions.filter(
          (option) => option._id === selectedDepositOption
        )?.[0] || null
      )
    }
    return null
  }, [selectedDepositOption, depositOptions])

  const selectedParkingData = useMemo(() => {
    const array = new Set(selectedParking.map((item) => item))

    return parkings.filter((item) => array.has(item._id))
  }, [selectedParking, parkings])

  const selectedUpgradesData = useMemo(() => {
    const array = new Set(selectedUpgrades.map((item) => item))

    return upgrades.filter((item) => array.has(item._id))
  }, [selectedUpgrades, upgrades])

  const isValid = useMemo(() => {
    if (!selectedPrimaryBuyer) {
      return false
    } else if (!selectedDepositOption) {
      return false
    } else if (!selectedUnit) {
      return false
    } else if (!selectedOfferStatus) {
      return false
    } else {
      return true
    }
  }, [
    selectedPrimaryBuyer,
    selectedDepositOption,
    selectedUnit,
    selectedOfferStatus,
  ])

  const handleAddressChange = (e) => {
    const newAddress = e.target.value

    setFintrac((prevState) => ({
      ...prevState,
      transactionPropertyAddress: newAddress,
    }))
  }

  const handleThirdPartyDescriptionChange = (e) => {
    const description = e.target.value
    setFintrac((prevState) => ({
      ...prevState,
      thirdPartDescription: description,
    }))
  }

  const handleThirdPartyDetailChange = (field) => (e) => {
    const value = e.target.value
    setFintrac((prevState) => ({
      ...prevState,
      thirdPartRecord: {
        ...prevState.thirdPartRecord,
        [field]: value,
      },
    }))
  }

  const handleRealtorInputChange = (field) => (e) => {
    const value = e.target.value
    setRealtorData((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const Options = useMemo(() => {
    return combineArray(selectedParking, selectedUpgrades, CustomSectionData)
  })

  const handlePurchase = () => {
    if (isValid) {
      setIsCreating(true)
      const createOfferParams = {
        unit: selectedUnit,
        status: selectedOfferStatus,
        buyer: [selectedPrimaryBuyer, selectSecondaryBuyer]?.filter((e) => e),
        totalCost: units.filter((unit) => unit._id === selectedUnit)?.[0]
          ?.price,
        subjects: subjects,
        depositOption: selectedDepositOption,
        deposit: {
          installments: deposits,
          optionName: selectedDepositOptionData.depositOptionName,
        },
        options: Options,
        fintrac: {
          businessRelationship: fintrac.businessRelationship,
          businessRelationshipExplanation:
            fintrac.businessRelationshipExplanation,
          thirdPart: fintrac.thirdPart,
          thirdPartRecord: fintrac.thirdPartRecord,
          thirdPartDescription: fintrac.thirdPartDescription,
        },
      }

      createOrUpdateOffer(createOfferParams, true)
        .then((response) => {
          setIsCreating(false)
          if (response?._id) {
            history.push(`${ADMIN_OFFERS}?transactionId=${response._id}`)
          }
        })
        .catch((error) => {
          console.error("error: ", error)
          setIsCreating(false)
        })
    }
  }

  return (
    <div className="flex flex-col min-h-screen relative">
      <div className="font-semibold text-2xl !px-5 pt-10">
        Create New Transaction
      </div>
      <div className="h-full w-full flex !p-5 gap-5">
        <div className="flex flex-col gap-5 flex-1">
          <Card className="w-full p-4 flex-col justify-start items-start gap-2 md:gap-4 inline-flex bg-white">
            <CardHeader className="p-0">
              <CardTitle>Transaction Summary</CardTitle>
            </CardHeader>
            <CardContent className="grid w-full gap-5 px-0">
              <div className="w-full">
                <p className="text-sm font-medium mb-1">Primary Buyer*</p>
                <CustomSelect
                  value={selectedPrimaryBuyer}
                  handleChange={setSelectedPrimaryBuyer}
                  options={filteredUsers?.data?.filter(
                    (user) => user?._id !== selectSecondaryBuyer
                  )}
                  showSearch
                  isName
                  placeholder="Select Primary Buyer"
                  disabled={isCreating}
                />
              </div>
              <div>
                <p className="text-sm font-medium mb-1">Secondary Buyer</p>
                <CustomSelect
                  value={selectSecondaryBuyer}
                  handleChange={setSelectSecondaryBuyer}
                  options={filteredUsers?.data?.filter(
                    (user) => user?._id !== selectedPrimaryBuyer
                  )}
                  showSearch
                  isName
                  placeholder="Select Secondary Buyer"
                  disabled={isCreating}
                />
              </div>
              <div>
                <p className="text-sm font-medium mb-1">Date purchased*</p>
                <CustomInput
                  containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                  value={moment(purchaseDate).format("YYYY-MM-DD")}
                  type="date"
                  onChange={(e) => {
                    let date = moment(e.target.value, "YYYY-MM-DD")
                      .startOf("day")
                      .toISOString()
                    setPurchaseDate(date)
                  }}
                  placeholder="Enter Purchase Date"
                  min={moment("1970-01-01", "YYYY-MM-DD").format("YYYY-MM-DD")}
                  disabled={isCreating}
                />
              </div>
              <div className="w-full">
                <p className="text-sm font-medium mb-1">Deposit Options*</p>
                <CustomSelect
                  value={selectedDepositOption}
                  handleChange={setSelectedDepositOption}
                  options={depositOptions
                    ?.filter((depositOption) => depositOption?.isActivated)
                    ?.map((data) => ({
                      ...data,
                      label: data.depositOptionName,
                      value: data._id,
                    }))}
                  showSearch
                  placeholder="Select Deposit Options"
                  disabled={isCreating}
                />
              </div>
              <div>
                <p className="text-sm font-medium mb-1">Unit to purchase*</p>
                <CustomSelect
                  value={units?.length ? selectedUnit : null}
                  handleChange={setSelectedUnit}
                  options={lotsInventoryOptions}
                  showSearch
                  placeholder="Select Unit"
                  disabled={isCreating}
                />
              </div>
              <div className="w-full">
                <p className="text-sm font-medium mb-1">Offer Status*</p>
                <CustomSelect
                  value={selectedOfferStatus}
                  handleChange={setSelectedOfferStatus}
                  options={OFFER_STATUS_OPTIONS}
                  showSearch
                  placeholder="Select Offer Status"
                  disabled={isCreating}
                />
              </div>
            </CardContent>
          </Card>

          {/* <Card className="w-full bg-white">
              <CardHeader>
                <CardTitle>Realtor Details</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                {!newBroker && (
                  <div className="flex flex-col gap-5">
                    <div className="space-y-2">
                      <label className="text-sm font-medium" htmlFor="realtor">
                        Select Realtor
                      </label>
                    </div>
                    <CustomSelect
                      value={broker}
                      handleChange={(value) => setBroker(value)}
                      options={BROKER}
                      placeholder="Select Broker"
                      disabled={isCreating}
                    />
                    <Button
                      variant="outline"
                      className="w-full sm:w-auto"
                      onClick={() => setNewBroker(true)}
                    >
                      <Plus className="mr-2 h-4 w-4" />
                      Add New Realtor
                    </Button>
                  </div>
                )}
                {newBroker && (
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <label
                        htmlFor="realtorName"
                        className="text-sm font-medium"
                      >
                        Realtor Name
                      </label>
                      <Input
                        id="realtorName"
                        placeholder="Enter realtor name"
                        className="rounded-md min-h-[40px] mt-0"
                        value={realtorData.realtorName}
                        onChange={handleRealtorInputChange("realtorName")}
                      />
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="brokerageName"
                        className="text-sm font-medium"
                      >
                        Brokerage Name
                      </label>
                      <Input
                        id="brokerageName"
                        placeholder="Enter brokerage name"
                        className="rounded-md min-h-[40px] mt-0"
                        value={realtorData.brokerageName}
                        onChange={handleRealtorInputChange("brokerageName")}
                      />
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="brokerageAddress"
                        className="text-sm font-medium"
                      >
                        Brokerage Address
                      </label>
                      <Input
                        id="brokerageAddress"
                        placeholder="Enter brokerage address"
                        className="rounded-md min-h-[40px] mt-0"
                        value={realtorData.brokerageAddress}
                        onChange={handleRealtorInputChange("brokerageAddress")}
                      />
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="brokeragePhone"
                        className="text-sm font-medium"
                      >
                        Brokerage Phone
                      </label>
                      <Input
                        id="brokeragePhone"
                        placeholder="Enter brokerage phone"
                        className="rounded-md min-h-[40px] mt-0"
                        value={realtorData.brokeragePhone}
                        onChange={handleRealtorInputChange("brokeragePhone")}
                      />
                    </div>

                    <div className="space-y-2">
                      <label htmlFor="email" className="text-sm font-medium">
                        Email
                      </label>
                      <Input
                        id="email"
                        type="email"
                        placeholder="Enter email"
                        className="rounded-md min-h-[40px] mt-0"
                        value={realtorData.email}
                        onChange={handleRealtorInputChange("email")}
                      />
                    </div>

                    <div className="flex justify-start space-x-4 !mt-8">
                      <Button
                        variant="outline"
                        onClick={() => setNewBroker(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="bg-[#1a2f4e] hover:bg-[#15253e]"
                        onClick={() => console.log(realtorData)}
                      >
                        Save Realtor
                      </Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card> */}

          <Card className="bg-white">
            <CardHeader className="flex flex-col">
              <div className="flex justify-between">
                <CardTitle className="">Subjects</CardTitle>
                <Button variant="outline" size="sm" onClick={addSubject}>
                  <Plus className="mr-2 h-4 w-4" />
                  Add Subject
                </Button>
              </div>
            </CardHeader>
            <CardContent className="space-y-4">
              {subjects.map((subject, index) => (
                <div
                  key={index}
                  className={`flex flex-col gap-3 ${
                    subjects.length - 1 === index ? "" : "border-b pb-4"
                  }`}
                >
                  <div className="flex flex-col gap-1">
                    <div
                      className="text-sm font-medium text-destructive"
                      htmlFor="description"
                    >
                      Description
                    </div>
                    <div className="relative flex gap-2">
                      <Textarea
                        id={`description-${index}`}
                        placeholder="Enter subject description"
                        className="min-h-[100px] resize-none border p-2 rounded"
                        value={subject.description}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                      <Button
                        variant="ghost"
                        size="icon"
                        className=" h-6 w-6 text-muted-foreground hover:text-foreground"
                        onClick={() => deleteSubject(index)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                  <div className="grid gap-4 md:grid-cols-2">
                    <div className="space-y-2 flex flex-col gap-1">
                      <div className="text-sm font-medium " htmlFor="type">
                        Type
                      </div>
                      <CustomSelect
                        value={subject.type}
                        handleChange={(value) => {
                          const updatedSubjects = [...subjects]
                          updatedSubjects[index] = {
                            ...updatedSubjects[index],
                            type: value,
                          }
                          setSubjects(updatedSubjects)
                        }}
                        options={SUBJECT_TYPE}
                        placeholder="Select Subject Type"
                      />
                    </div>
                    <div className="space-y-2 flex flex-col gap-1">
                      <div className="text-sm font-medium" htmlFor="due-date">
                        Due Date
                      </div>

                      <CustomInput
                        containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                        value={moment(subjects[index].dueDate).format(
                          "YYYY-MM-DD"
                        )} // Access the correct subject's date
                        type="date"
                        onChange={(e) => {
                          const updatedSubjects = [...subjects]
                          const date = moment(e.target.value, "YYYY-MM-DD")
                            .startOf("day")
                            .toISOString()
                          updatedSubjects[index] = {
                            ...updatedSubjects[index],
                            dueDate: date,
                          }
                          setSubjects(updatedSubjects) // Update the state for the correct index
                        }}
                        placeholder="Enter Purchase Date"
                        min={moment("1970-01-01", "YYYY-MM-DD").format(
                          "YYYY-MM-DD"
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>

          {/* Deposits Card */}
          <Card className="bg-white">
            <CardHeader className="flex flex-row items-center justify-between space-y-0">
              <CardTitle className="">Deposits</CardTitle>
              <Button variant="outline" size="sm" onClick={addDeposit}>
                <Plus className="mr-2 h-4 w-4" />
                Add Deposit
              </Button>
            </CardHeader>
            <CardContent className="space-y-4">
              {deposits.map((deposit, index) => {
                return (
                  <div className="flex items-center">
                    <div className="flex flex-col gap-4 flex-1">
                      <div className="grid gap-4 md:grid-cols-2">
                        <div className="space-y-2">
                          <div className="text-sm font-medium" htmlFor="name">
                            Name
                          </div>
                          <Input
                            id={`name-${index}`}
                            className="border rounded px-3 h-[40px] !py-[14px] w-full"
                            placeholder="Enter Name"
                            value={deposit.name}
                            onChange={(e) =>
                              handleDepositChange(index, "name", e.target.value)
                            }
                          />
                        </div>
                        <div className="space-y-2">
                          <div className="text-sm font-medium" htmlFor="value">
                            Amount
                          </div>
                          <Input
                            id={`value-${index}`}
                            type="number"
                            className="border rounded px-3 h-[40px] !py-[14px] w-full"
                            placeholder="Enter Amount"
                            value={deposit.value}
                            onChange={(e) =>
                              handleDepositChange(
                                index,
                                "value",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="grid gap-4 md:grid-cols-3">
                        <div className="space-y-2">
                          <div
                            className="text-sm font-medium"
                            htmlFor="percentage"
                          >
                            Percentage
                          </div>
                          <Input
                            id={`percentage-${index}`}
                            type="number"
                            className="border rounded px-3 h-[40px] !py-[14px] w-full"
                            placeholder="Enter Percentage"
                            value={deposit.percentage}
                            onChange={(e) =>
                              handleDepositChange(
                                index,
                                "percentage",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="space-y-2">
                          <div
                            className="text-sm font-medium"
                            htmlFor="payment-method"
                          >
                            Payment Method
                          </div>

                          <CustomSelect
                            value={deposit?.paymentMethodType}
                            handleChange={(value) => {
                              const updatedDeposits = [...deposits]
                              updatedDeposits[index] = {
                                ...updatedDeposits[index],
                                paymentMethodType: value,
                              }
                              setDeposits(updatedDeposits)
                            }}
                            options={PAYMENT_METHOD}
                            placeholder="Select Subject Type"
                          />
                        </div>
                        <div className="space-y-2">
                          <div
                            className="text-sm font-medium"
                            htmlFor="deposit-due-date"
                          >
                            Due Date
                          </div>
                          <CustomInput
                            containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                            value={moment(deposits[index]?.dueDate).format(
                              "YYYY-MM-DD"
                            )} // Access the correct subject's date
                            type="date"
                            onChange={(e) => {
                              const updatedDeposit = [...deposits]
                              const date = moment(e.target.value, "YYYY-MM-DD")
                                .startOf("day")
                                .toISOString()
                              updatedDeposit[index] = {
                                ...updatedDeposit[index],
                                dueDate: date,
                              }
                              setDeposits(updatedDeposit) // Update the state for the correct index
                            }}
                            placeholder="Enter Purchase Date"
                            min={moment("1970-01-01", "YYYY-MM-DD").format(
                              "YYYY-MM-DD"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-6 w-6 text-muted-foreground hover:text-foreground"
                        onClick={() => removeDeposit(index)}
                      >
                        <Trash2 className="h-4 w-4" />
                        <span className="sr-only">Delete deposit</span>
                      </Button>
                    </div>
                  </div>
                )
              })}
            </CardContent>
          </Card>

          <Card className="w-full bg-white">
            <CardHeader>
              <CardTitle>FINTRAC Information</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-2">
                <label htmlFor="date">Date Information Verified</label>
                <CustomInput
                  containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                  value={moment(fintrac.dateInformationVerified).format(
                    "YYYY-MM-DD"
                  )} // Bind to state
                  type="date"
                  onChange={(e) => {
                    const date = moment(e.target.value, "YYYY-MM-DD")
                      .startOf("day")
                      .toISOString()
                    setFintrac((prevState) => ({
                      ...prevState,
                      dateInformationVerified: date, // Update the purchaseDate field
                    }))
                  }}
                  placeholder="Enter Purchase Date"
                  min={moment("1970-01-01", "YYYY-MM-DD").format("YYYY-MM-DD")}
                  disabled={isCreating}
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="address">Transaction Property Address</label>
                <Input
                  type="text"
                  id="address"
                  placeholder="Enter property address"
                  value={fintrac.transactionPropertyAddress} // Bind input value to state
                  onChange={handleAddressChange} // Handle input change
                  className="border rounded px-2 py-1"
                />
              </div>

              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={fintrac.thirdPart}
                  handleChange={() =>
                    setFintrac({
                      ...fintrac,
                      thirdPart: !fintrac.thirdPart,
                    })
                  }
                  label="Third Party Involved"
                />
              </div>
              {fintrac.thirdPart && (
                <div className="w-full max-w-2xl">
                  <div className="space-y-6">
                    {/* Third Party Description */}
                    <div className="space-y-2">
                      <label htmlFor="description">
                        Third Party Description
                      </label>
                      <Textarea
                        id="description"
                        className="min-h-[100px] resize-none"
                        placeholder="Enter third party description"
                        value={fintrac.thirdPartDescription}
                        onChange={handleThirdPartyDescriptionChange}
                      />
                    </div>

                    {/* Third Party Details */}
                    <div className="space-y-4">
                      <h2 className="text-lg font-semibold">
                        Third Party Details
                      </h2>

                      {/* Name */}
                      <div className="space-y-2">
                        <label htmlFor="name">Name</label>
                        <Input
                          id="name"
                          placeholder="Enter name"
                          value={fintrac.thirdPartRecord.name}
                          onChange={handleThirdPartyDetailChange("name")}
                        />
                      </div>

                      {/* Address */}
                      <div className="space-y-2">
                        <label htmlFor="address">Address</label>
                        <Input
                          id="address"
                          placeholder="Enter address"
                          value={fintrac.thirdPartRecord.address}
                          onChange={handleThirdPartyDetailChange("address")}
                        />
                      </div>

                      {/* Phone Number */}
                      <div className="space-y-2">
                        <label htmlFor="phone">Phone Number</label>
                        {/* <Input
                          id="phone"
                          type="tel"
                          placeholder="Enter phone number"
                          value={fintrac.thirdPartRecord.phoneNumber}
                          onChange={handleThirdPartyDetailChange("phone")}
                        /> */}

                        <PhoneInput
                          placeholder="Enter your phone number"
                          defaultCountry="US"
                          value={fintrac.thirdPartRecord.phoneNumber}
                          onChange={(value) =>
                            setFintrac((prevState) => ({
                              ...prevState,
                              thirdPartRecord: {
                                ...prevState.thirdPartRecord,
                                phoneNumber: value,
                              },
                            }))
                          }
                          className="border border-softBlack h-[42px] rounded text-softBlack font-normal placeholder-softBlack60 pl-2 overflow-hidden"
                        />
                      </div>

                      {/* Date of Birth */}
                      <div className="space-y-2">
                        <label htmlFor="dob">Date of Birth</label>
                        <CustomInput
                          containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                          type="date"
                          id="dob"
                          value={moment(
                            fintrac.thirdPartRecord.birthday
                          ).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            const date = moment(e.target.value, "YYYY-MM-DD")
                              .startOf("day")
                              .toISOString()
                            setFintrac((prevState) => ({
                              ...prevState,
                              thirdPartRecord: {
                                ...prevState.thirdPartRecord,
                                birthday: date,
                              },
                            }))
                          }}
                        />
                      </div>

                      {/* Occupation */}
                      <div className="space-y-2">
                        <label htmlFor="occupation">Occupation</label>
                        <Input
                          id="occupation"
                          placeholder="Enter occupation"
                          value={fintrac.thirdPartRecord.occupation}
                          onChange={handleThirdPartyDetailChange("occupation")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="space-y-4">
                <h3 className="text-base font-medium">Risk Assessment</h3>

                <div className="space-y-2">
                  <label>Risk Level</label>

                  {["Low", "Medium", "High"].map((level) => (
                    <div key={level} className="flex items-center space-x-2">
                      <input
                        type="radio"
                        id={level}
                        name="riskLevel"
                        value={level}
                        checked={fintrac.riskAssessment.riskLevel === level}
                        onChange={() => handleRiskLevelChange(level)}
                      />
                      <label htmlFor={level}>{level}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="space-y-2">
                <label htmlFor="risk-explanation">Risk Explanation</label>
                <Textarea
                  id="risk-explanation"
                  placeholder="Enter risk explanation"
                  className="min-h-[100px] w-full p-2 border rounded"
                  value={fintrac.riskAssessment.riskExplanation}
                  onChange={(e) => {
                    const newValue = e.target.value
                    setFintrac((prevState) => ({
                      ...prevState,
                      riskAssessment: {
                        ...prevState.riskAssessment,
                        riskExplanation: newValue,
                      },
                    }))
                  }}
                />
              </div>

              <div className="space-y-4">
                <h3 className="text-base font-medium">Business Relationship</h3>
                <div className="space-y-3">
                  {/* Residential Property */}
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={
                        fintrac.businessRelationshipData.residentialProperty
                      }
                      handleChange={() =>
                        handleCheckboxChange("residentialProperty")
                      }
                      id="residential"
                    />
                    <label htmlFor="residential">Residential Property</label>
                  </div>

                  {/* Residential Property for Income Purposes */}
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={
                        fintrac.businessRelationshipData
                          .residentialPropertyForIncomePurposes
                      }
                      handleChange={() =>
                        handleCheckboxChange(
                          "residentialPropertyForIncomePurposes"
                        )
                      }
                      id="residential-income"
                    />
                    <label htmlFor="residential-income">
                      Residential Property for Income Purposes
                    </label>
                  </div>

                  {/* Commercial Property */}
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={
                        fintrac.businessRelationshipData.commercialProperty
                      }
                      handleChange={() =>
                        handleCheckboxChange("commercialProperty")
                      }
                      id="commercial"
                    />
                    <label htmlFor="commercial">Commercial Property</label>
                  </div>

                  {/* Land for Commercial Use */}
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={fintrac.businessRelationshipData.commercialLand}
                      handleChange={() =>
                        handleCheckboxChange("commercialLand")
                      }
                      id="commercial-land"
                    />
                    <label htmlFor="commercial-land">
                      Land for Commercial Use
                    </label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      checked={fintrac.businessRelationshipData.others}
                      handleChange={() => handleCheckboxChange("others")}
                      id="commercial-land"
                    />
                    <label htmlFor="commercial-land">Others</label>
                  </div>

                  {fintrac.businessRelationshipData.others && (
                    <div className="space-y-2">
                      <label>Other</label>
                      <Textarea
                        id="description"
                        className="min-h-[100px] resize-none"
                        placeholder="Enter other description"
                        value={fintrac.businessRelationshipExplanation}
                        onChange={(e) =>
                          setFintrac((prevState) => ({
                            ...prevState,
                            businessRelationshipExplanation: e.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="w-full bg-white">
            <CardHeader>
              <CardTitle className="">Parking Information</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="text-sm font-medium">Select Parking</div>
              <CustomSelect
                value={selectedParking.map((item) => item._id)}
                handleChange={(selectedIds) => {
                  const selectedObjects = parkings.filter((parking) =>
                    selectedIds.includes(parking._id)
                  )
                  setSelectedParking(selectedObjects)
                }}
                options={parkingOptions}
                showSearch
                mode="multiple"
                placeholder="Select Parking Option"
                disabled={isCreating}
              />
            </CardContent>
          </Card>

          <Card className="w-full bg-white">
            <CardHeader>
              <CardTitle className="">Upgrades and Options</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="text-sm font-medium">
                Select Upgrades and Options
              </div>
              <CustomSelect
                value={selectedUpgrades.map((upgrade) => upgrade._id)}
                handleChange={(selectedIds) => {
                  const selectedObjects = upgrades.filter((upgrade) =>
                    selectedIds.includes(upgrade._id)
                  )
                  setSelectedUpgrades(selectedObjects)
                }}
                options={upgradesOptions}
                showSearch
                mode="multiple"
                placeholder="Select Upgrade Option"
                disabled={isCreating}
              />
            </CardContent>
          </Card>

          <Card className="w-full bg-white">
            <CardHeader>
              <CardTitle className="">Custom Section</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              {customSections.map((section) => {
                return (
                  <div key={section._id}>
                    <p className="mb-1 text-sm font-medium">{section.title}</p>
                    <CustomSelect
                      value={
                        selectedSections[section._id]?.map((obj) => obj._id) ||
                        []
                      }
                      handleChange={(values) =>
                        handleSectionChange(section._id, values)
                      }
                      options={customSectionOptions
                        .filter((option) => option.type === section._id)
                        .map((data) => ({
                          label: `${data.name}`,
                          value: data._id,
                        }))}
                      showSearch
                      mode="multiple"
                      placeholder="Select Custom Section"
                      disabled={isCreating}
                    />
                  </div>
                )
              })}
            </CardContent>
          </Card>
        </div>
        <div className="min-w-[500px] sticky top-10 h-fit">
          <Card className="bg-white">
            <CardContent className="m-6 flex flex-col gap-3 border border-zinc-400 rounded-md">
              <div className="pt-6 border-b border-zinc-400 pb-2">
                <h2 className="text-lg font-semibold text-gray-700">
                  Unit Details
                </h2>
                <div className="grid grid-cols-2 gap-y-2 gap-x-4">
                  <div className="flex items-center justify-between gap-5">
                    <span className="text-sm text-gray-500">View</span>
                    <span className="font-medium">-</span>
                  </div>
                  <div className="flex items-center gap-5 justify-between">
                    <span className="text-sm text-gray-500">Area</span>
                    <span className="font-medium">-</span>
                  </div>

                  <div className="flex items-center justify-between gap-2">
                    <span className="text-sm text-gray-500">Deck Plan</span>
                    <span className="font-medium">-</span>
                  </div>
                  <div className="flex items-center justify-between gap-1 ">
                    <span className="text-sm text-gray-500">Type</span>
                    <span className="font-medium">
                      {selectedUnitData?.numberOfBedrooms}BD
                    </span>
                  </div>

                  <div className="flex items-center justify-between gap-2">
                    <span className="text-sm text-gray-500">Unit</span>
                    <span className="font-medium">
                      {selectedUnitData?.unitNumber}
                    </span>
                  </div>
                  <div className="flex items-center gap-1 justify-between">
                    <span className="text-sm text-gray-500">SL.</span>
                    <span className="font-medium">
                      {selectedUnitData?.strataLotNumber}
                    </span>
                  </div>

                  <div className="flex items-center justify-between gap-2">
                    <span className="text-sm text-gray-500">Level</span>
                    <span className="font-medium">
                      {selectedUnitData?.floorNumber}
                    </span>
                  </div>
                </div>
              </div>
              {selectedDepositOptionData?._id && (
                <div className="flex flex-col gap-3 border-b border-zinc-400 pb-2">
                  <div className="text-lg font-semibold text-gray-700">
                    Deposit
                  </div>
                  <div className="flex justify-between">
                    <div className="text-sm text-gray-500">Option Name</div>
                    <div>{selectedDepositOptionData?.depositOptionName}</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-sm text-gray-500">Discount</div>
                    <div>{selectedDepositOptionData?.discountPercentage}%</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500 mb-2">
                      Deposit Percentages
                    </div>
                    <div className="grid grid-cols-4 gap-2">
                      {selectedDepositOptionData?.optionPercentages.map(
                        (data, index) => (
                          <div
                            key={index}
                            className="rounded-lg border shadow-sm p-2.5 relative overflow-hidden"
                          >
                            <div className="absolute top-0 left-0 text-xs bg-black text-white px-1 rounded-br-md">
                              {index + 1}
                            </div>
                            <div className="text-xl font-semibold pt-2">
                              {data}%
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div>
                <h2 className="text-lg font-semibold text-gray-700">Pricing</h2>
                <div className="grid grid-cols-2 gap-y-2">
                  <span className="text-sm text-gray-500">List Price</span>
                  <span className="text-right font-medium">
                    {selectedUnitData?.priceCurrencyType} $
                    {selectedUnitData?.price}
                  </span>

                  <span className="text-sm text-gray-500">$/SQFT</span>
                  <span className="text-right font-medium">-</span>

                  <span className="text-sm text-gray-500">Status</span>
                  <span className="text-right font-medium">
                    {selectedUnitData?.status}
                  </span>
                </div>
              </div>

              {/* <div>
                <h2 className="text-lg font-semibold text-gray-700">
                  Realtor Details
                </h2>
                <p className="text-sm text-gray-500">No realtor selected</p>
              </div> */}

              <div>
                <h2 className="text-lg font-semibold text-gray-700">
                  Transaction Details
                </h2>
                <div className="grid grid-cols-2 gap-y-2">
                  <span className="text-sm text-gray-500">Project</span>
                  <span className="text-right font-medium">Not specified</span>

                  <span className="text-sm text-gray-500">Offer Date</span>
                  <span className="text-right font-medium">Not specified</span>
                </div>
              </div>

              <div>
                <h2 className="text-lg font-semibold text-gray-700">Buyers</h2>
                <div className="grid grid-cols-2 gap-y-2">
                  <span className="text-sm text-gray-500">Primary</span>
                  <span className="text-right font-medium">
                    {
                      filteredUsers?.data.filter(
                        (data) => data._id === selectedPrimaryBuyer
                      )[0]?.fullName
                    }
                  </span>

                  <span className="text-sm text-gray-500">Co-Buyer 1</span>
                  <span className="text-right font-medium">
                    {
                      filteredUsers?.data.filter(
                        (data) => data._id === selectSecondaryBuyer
                      )[0]?.fullName
                    }
                  </span>
                </div>
              </div>
              {(subjects[0].dueDate !== "" || subjects[0].type !== "") && (
                <div>
                  <h2 className="text-lg font-semibold text-gray-700">
                    Subject
                  </h2>
                  {subjects?.map((subject) => {
                    return (
                      <div className="grid grid-cols-2 gap-y-2">
                        <span className="text-sm text-gray-500">Type</span>
                        <span className="text-right font-medium">
                          {subject.type}
                        </span>
                        <span className="text-sm text-gray-500">Due Date</span>
                        <span className="text-right font-medium">
                          {moment(subject.dueDate).format("L")}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
              {selectedParkingData.length > 0 && (
                <div>
                  <h2 className="text-lg font-semibold text-gray-700">
                    Parking Information
                  </h2>
                  {selectedParkingData?.map((parking) => {
                    return (
                      <div className="grid grid-cols-2 justify-center items-center gap-y-1 mb-2">
                        <span className="text-sm text-gray-500">Name</span>
                        <span className="text-right font-medium">
                          {parking.name}
                        </span>
                        <span className="text-sm text-gray-500 ">Value</span>
                        <span className="text-right font-medium">
                          CAD${parking.value}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
              {selectedUpgradesData.length > 0 && (
                <div>
                  <h2 className="text-lg font-semibold text-gray-700">
                    Upgrade Information
                  </h2>
                  {selectedUpgradesData?.map((upgrade) => {
                    return (
                      <div className="grid grid-cols-2 justify-center items-center gap-y-1 mb-2">
                        <span className="text-sm text-gray-500">Name</span>
                        <span className="text-right font-medium">
                          {upgrade.name}
                        </span>
                        <span className="text-sm text-gray-500 ">Value</span>
                        <span className="text-right font-medium">
                          CAD${upgrade.value}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
              {CustomSectionData.length > 0 && (
                <div className="flex flex-col gap-3">
                  <h2 className="text-lg font-semibold text-gray-700">
                    Custom Section
                  </h2>
                  {customSections?.map((section) => {
                    return (
                      <div className="flex flex-col gap-2">
                        <span className="font-medium">{section.title}</span>
                        {customSectionOptions
                          .filter((data) => data.type == section._id)
                          .map((data) => {
                            return (
                              <div className="grid grid-cols-2 justify-center items-center gap-y-1 mb-2 border p-2 border-black rounded-[5px]">
                                <span className="text-sm text-gray-500 ">
                                  name
                                </span>
                                <span className="text-right font-medium">
                                  {data.name}
                                </span>
                                <span className="text-sm text-gray-500 ">
                                  Value
                                </span>
                                <span className="text-right font-medium">
                                  CAD${data.value}
                                </span>
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                </div>
              )}

              {/* <div>
                <h2 className="text-lg font-semibold text-gray-700">
                  Additional Details
                </h2>
              </div> */}

              {/* <div>
               <h2 className="text-lg font-semibold text-gray-700">FINTRAC Details</h2>
               <div className="grid grid-cols-2 gap-y-2">
               </div>
             </div> */}
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="w-full flex justify-end flex-1 p-4 items-end">
        <CustomButton
          className="w-fit"
          handleClick={handlePurchase}
          disabled={!isValid || isCreating}
        >
          <span className="font-medium py-2 px-8 text-lg flex gap-2 items-center">
            {isCreating && <Oval height="18" width="18" color="white" />}
            Purchase
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default QuickForm
