/* eslint-disable */
// @ts-nocheck
import { useHistory } from "react-router-dom";
import { ADMIN_CONTACTS, ADMIN_CORPORATE_CONTACTS, EMAIL_INBOX_CORPORATE, CALENDAR_CORPORATE, ADMIN_DASHBOARD_CORPORATE, CORPORATE_DEMAND_NOTES_SETTINGS, ADMIN_CORPORATE_TASKS, ADMIN_CUSTOM_FORMS_CORPORATE, ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE, ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE, ADMIN_LISTS_CORPORATE, ADMIN_MASTER_REPORT_CORPORATE, ADMIN_SMS_BROADCAST_CORPORATE, TASKS_CALENDAR_CORPORATE, ADMIN_WORKFLOWS } from "routes";

import { ReactComponent as DashboardIconSVG } from '../../assets/icons/icn_dashboard.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/icn_email.svg';
import { ReactComponent as ContactsIcon } from '../../assets/icons/icn_contacts.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/icn_calendar.svg';
import { ReactComponent as OpportunitiesIcon } from '../../assets/icons/icn_reduce_capacity.svg';
import { ReactComponent as ReservationsIcon } from '../../assets/icons/icn_dns.svg';
import { ReactComponent as BrokerIcon } from '../../assets/icons/icn_text_snippet.svg';
import { ReactComponent as ContractsIcon } from '../../assets/icons/icn_local_offer.svg';
import { ReactComponent as DepositsIcon } from '../../assets/icons/icn_local_atm.svg';
import { ReactComponent as InventoryIcon } from '../../assets/icons/icn_card_view.svg';
import { ReactComponent as EmailCampaignsIcon } from '../../assets/icons/icn_email_sent.svg';
import { ReactComponent as ContractTemplatesIcon } from '../../assets/icons/icn_sticky_note_2.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/icn_assessment.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/icn_person.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/icn_people.svg';
import { ReactComponent as BuildingsIcon } from '../../assets/icons/icn_apartment.svg';
import { ReactComponent as ProjectManagementIcon } from '../../assets/icons/icn_ballot.svg';
import { ReactComponent as DropDownSettingsIcon } from '../../assets/icons/icn_bulleted_list.svg';
import { ReactComponent as EventsIcon } from '../../assets/icons/icn_presentation.svg';
import { ReactComponent as LeadGenerationIcon } from '../../assets/icons/icn_link.svg';
import { ReactComponent as PostSalesSuccessIcon } from '../../assets/icons/icn_check_circle.svg';
import { ClipboardTypeIcon, FormInputIcon, WorkflowIcon } from 'lucide-react';

const useCorporateSideBarMenu = () => {
  const history = useHistory();

  const CORPORATE_SIDE_BAR_MENU = {
    corporate: [
      {
        key: 'dashboard',
        title: 'Dashboard',
        hasSubMenus: false,
        Icon: DashboardIconSVG,
        onClick: () =>
          history.push(
            ADMIN_DASHBOARD_CORPORATE
          ),
        route:
          ADMIN_DASHBOARD_CORPORATE,
        permissions: 'DASHBOARD_CORPORATE',
      },
      {
        key: "contacts",
        title: "Contacts",
        hasSubMenus: false,
        Icon: ContactsIcon,
        onClick: () => history.push(ADMIN_CORPORATE_CONTACTS),
        route: ADMIN_CORPORATE_CONTACTS,
        permissions: "CONTACTS",
      },
      {
        key: 'tasks',
        title: 'Tasks',
        hasSubMenus: true,
        Icon: PostSalesSuccessIcon,
        subMenus: [
          {
            key: 'tasks-list',
            title: 'List',
            hasSubMenus: false,
            onClick: () => history.push(ADMIN_CORPORATE_TASKS),
            route: ADMIN_CORPORATE_TASKS,
            permissions: 'LIST_CORPORATE',
          },
          {
            key: 'tasks-calender',
            title: 'Calendar',
            hasSubMenus: false,
            onClick: () => history.push(TASKS_CALENDAR_CORPORATE),
            route: TASKS_CALENDAR_CORPORATE,
            permissions: 'TASK_CALENDAR_CORPORATE',
          },
        ],
      },
      {
        key: 'email inbox',
        title: 'Inbox',
        hasSubMenus: false,
        Icon: EmailIcon,
        onClick: () => history.push(EMAIL_INBOX_CORPORATE),
        route: EMAIL_INBOX_CORPORATE,
        permissions: 'EMAIL_INBOX_CORPORATE',
      },
      {
        key: 'calendar',
        title: 'Calendar',
        hasSubMenus: false,
        Icon: CalendarIcon,
        onClick: () => history.push(CALENDAR_CORPORATE),
        route: CALENDAR_CORPORATE,
        permissions: 'CALENDAR_CORPORATE',
      },
      {
        key: 'email_Campaigns',
        title: 'Email Campaigns',
        hasSubMenus: true,
        Icon: DashboardIconSVG,
        // onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
        // route: 'emailCampaigns'
        subMenus: [
          // {
          //   key: 'campaigns',
          //   title: 'Email Campaigns Reports',
          //   hasSubMenus: false,
          //   Icon: EmailCampaignsIcon,
          //   onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
          //   route: ADMIN_EMAIL_CAMPAIGNS
          // },
          {
            key: ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE,
            title: 'Email Campaigns Builder',
            hasSubMenus: false,
            Icon: EmailIcon,
            onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE),
            route: ADMIN_EMAIL_CAMPAIGNS_BUILDER_CORPORATE,
            permissions: 'EMAIL_CAMPAIGNS_BUILDER_CORPORATE',
          },
          {
            key: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE,
            title: 'Email Campaigns Reports',
            hasSubMenus: false,
            Icon: EmailCampaignsIcon,
            onClick: () =>
              history.push(ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE),
            route: ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER_CORPORATE,
            permissions: 'EMAIL_CAMPAIGNS_REPORTS_CORPORATE',
          },
        ],
      },
      {
        key: 'sms broadcast',
        title: 'SMS Broadcast',
        hasSubMenus: false,
        Icon: EmailIcon,
        onClick: () => history.push(ADMIN_SMS_BROADCAST_CORPORATE),
        route: ADMIN_SMS_BROADCAST_CORPORATE,
        permissions: 'SMS_BROADCAST_CORPORATE',
      },
      {
        key: 'report',
        title: 'Reports',
        hasSubMenus: false,
        Icon: ReportsIcon,
        onClick: () => history.push(ADMIN_MASTER_REPORT_CORPORATE),
        route: ADMIN_MASTER_REPORT_CORPORATE,
        permissions: 'ADMIN_MASTER_REPORT_CORPORATE',
      },
      {
        key: 'lists',
        title: 'Lists Management',
        hasSubMenus: false,
        Icon: ContactsIcon,
        onClick: () => history.push(ADMIN_LISTS_CORPORATE),
        route: ADMIN_LISTS_CORPORATE,
        permissions: 'LISTS',
      },
      {
        key: 'corporate_demandnotes',
        title: 'Corporate Demand Notes',
        hasSubMenus: false,
        Icon: DropDownSettingsIcon,
        onClick: () => history.push(CORPORATE_DEMAND_NOTES_SETTINGS),
        route: CORPORATE_DEMAND_NOTES_SETTINGS,
        permissions: 'CORPORATE_DEMAND_NOTES_SETTINGS',
      }, {
        key: 'forms',
        title: 'Forms',
        hasSubMenus: false,
        Icon: ClipboardTypeIcon,
        onClick: () => history.push(ADMIN_CUSTOM_FORMS_CORPORATE),
        route: ADMIN_CUSTOM_FORMS_CORPORATE,
        permissions: 'FORMS',
      }, , {
        key: 'workflows',
        title: 'Workflows',
        hasSubMenus: false,
        Icon: WorkflowIcon,
        onClick: () => history.push(ADMIN_WORKFLOWS),
        route: ADMIN_WORKFLOWS,
        permissions: 'WORKFLOWS',
      },
    ],
  };

  const getCategory = () => {
    return { value: "corporate" };
  };

  return {
    CORPORATE_SIDE_BAR_MENU,
    getCategory,
  };
};

export default useCorporateSideBarMenu;
