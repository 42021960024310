// @ts-nocheck
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getSenderAccounts } from 'store/actions/useSendGrid'

const useGetSendGridSenderAccounts = () => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading, refetch } = useQuery(
    ['getSenderAccounts'],
    () => getSenderAccounts(),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
  )
  return { lists: data ?? null, isLoading, refetch }
}

export {
  useGetSendGridSenderAccounts
}
