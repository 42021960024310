/* eslint-disable */
// @ts-nocheck
import { OFFER_STATUS_OPTIONS } from "components/Inventory/components/QuickForm"
import { Badge } from "components/ShadcnComponents/badge"
import { Button } from "components/ShadcnComponents/button"
import CustomSelect from "../../../../../pages/reservation/NewReservation/components/CustomSelect"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card"
import { ExternalLink, Trash2, Upload } from "lucide-react"
import moment from "moment-timezone"
import React, { useEffect, useMemo, useState } from "react"
import { createOrUpdateOffer } from "store/actions/offerActions"
import { offerStatus } from "components/ProjectOffers"

const transactionStatus = [
  {
    id: "draft",
    label: "Draft",
    value: "draft",
  },
  {
    id: "prepared",
    label: "Prepared",
    value: "prepared",
  },
  {
    id: "pending-approval",
    label: "Pending Approval",
    value: "pending-approval",
  },
  {
    id: "approved-pending-execution",
    label: "approved-pending execution",
    value: "approved-pending-execution",
  },
  {
    id: "executed-pending-dev-approval",
    label: "Executed-pending dev-approval",
    value: "executed-pending-dev-approval",
  },
  {
    id: "approved-send-for-signature",
    label: "Approved-Send for signature",
    value: "approved-send-for-signature",
  },
  {
    id: "conveyed",
    label: "Conveyed",
    value: "conveyed",
  },
  {
    id: "rescission",
    label: "Rescission",
    value: "rescission",
  },
  {
    id: "subjects",
    label: "Subjects",
    value: "subjects",
  },
  {
    id: "firm",
    label: "Firm",
    value: "firm",
  },
  {
    id: "lost",
    label: "Lost",
    value: "lost",
  },
]

const TransactionOverviewTab = ({ transactionData }) => {
  const [selectedOfferStatus, setSelectedOfferStatus] = useState(
    transactionData?.status || null
  )
  const [isCreating, setIsCreating] = useState(false)

  const handleChange = (value) => {
    setSelectedOfferStatus(value)
    setIsCreating(true)
    const updateStatusParams = {
      status: value,
      id: transactionData?._id,
      unit: transactionData?.unit?._id,
    }

    createOrUpdateOffer(updateStatusParams, true)
      .then((response) => {
        setIsCreating(false)
      })
      .catch((error) => {
        setIsCreating(false)
        console.error("error: ", error)
      })
  }

  return (
    <div>
      <div className="mb-6 flex flex-col">
        <div className="flex-1">
          <div className="text-2xl font-semibold">Transaction Overview</div>
          <p className="text-sm text-muted-foreground">
            Unit-{transactionData?.unit?.unitNumber} -{" "}
            {transactionData?.buyer[0]?.firstName}{" "}
            {transactionData?.buyer[0]?.lastName}
          </p>
        </div>
        <div className="flex justify-between items-center gap-2">
          <div
            className={`p-2 px-4 text-center flex justify-center items-center text-white rounded-full ${offerStatus[selectedOfferStatus].backgroundColor}`}
          >
            {offerStatus[selectedOfferStatus].text}
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-[2px] text-zinc-400">Change Status</div>
            <CustomSelect
              value={selectedOfferStatus}
              handleChange={(value) => handleChange(value)}
              options={OFFER_STATUS_OPTIONS}
              placeholder="Select Offer Status"
              className="min-w-[250px]"
              disabled={isCreating}
            />
          </div>
        </div>
      </div>

      <div className="grid gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Basic Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Unit Number</label>
                  <p className="text-sm text-muted-foreground">
                    {transactionData?.unit?.unitNumber}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Offer Date</label>
                  <p className="text-sm text-muted-foreground">
                    {moment(transactionData?.createdAt).format("LL")}
                  </p>
                </div>
                <div>
                  <label className="text-sm font-medium">Home Design</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Sale Price</label>
                  <p className="text-sm text-muted-foreground">
                    {transactionData?.unit?.priceCurrencyType}{" "}
                    {transactionData?.unit?.price}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Project</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">Offer Version</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Buyer Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <label className="text-sm font-medium">Buyer 1</label>
                <p className="text-sm text-muted-foreground">
                  {transactionData?.buyer[0]?.firstName}{" "}
                  {transactionData?.buyer[0]?.lastName}
                </p>
              </div>
              <div>
                <label className="text-sm font-medium">Buyer 2</label>
                {transactionData?.buyer?.length >= 1 ? (
                  <p className="text-sm text-muted-foreground">
                    {transactionData?.buyer[1]?.firstName}{" "}
                    {transactionData?.buyer[1]?.lastName}
                  </p>
                ) : (
                  <p className="text-sm text-muted-foreground">-</p>
                )}
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Realtor Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Realtor Name</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Email</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">Move-in Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Brokerage</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Phone</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">
                    Commission Rate 2
                  </label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Key Dates</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Offer Date</label>
                  <p className="text-sm text-muted-foreground">
                    {moment(transactionData?.createdAt).format("LL")}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Firm Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">Completion Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Accepted Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">
                    Estimated Completion
                  </label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">
                    Not set Possession Date
                  </label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Subjects</CardTitle>
          </CardHeader>
          <CardContent>
            {transactionData?.subjects.length > 0 ? (
              <div className="rounded-lg border">
                <div className="flex items-center justify-between p-4">
                  <div className="space-y-1">
                    <div className="text-sm font-medium">
                      {transactionData?.subjects[0]?.subject_type}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {transactionData?.subjects[0]?.description}
                    </div>
                    <Badge variant="outline" className="text-xs">
                      {transactionData?.subjects[0]?.status}
                    </Badge>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="text-sm text-muted-foreground">
                      {moment(transactionData?.subjects[0]?.created_at).format(
                        "LL"
                      )}
                    </div>
                    {/* <Button variant="ghost" size="icon" className="h-8 w-8">
                    <Trash2 className="h-4 w-4" />
                    <span className="sr-only">Delete financing</span>
                  </Button> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-zinc-500 text-base">No subjects added</div>
            )}
          </CardContent>
        </Card>

        <Card className="w-full">
          <CardHeader>
            <CardTitle>Deposits</CardTitle>
          </CardHeader>
          <CardContent>
            {transactionData?.deposit?.installments.length > 0 ? (
              <div className="rounded-md border border-gray-200">
                <div className="p-4">
                  <h3 className="text-sm font-semibold">Standard</h3>
                  <div className="mt-3 rounded-md border border-gray-200">
                    <div className="flex items-center justify-between p-4">
                      <div className="space-y-1">
                        <div className="text-sm font-medium">
                          Initial Deposit
                        </div>
                        {/* <div className="text-xs text-gray-500">
                        Due: Dec 05, 2024
                      </div>
                      <div className="text-xs text-gray-500">
                        Received by: Susann on Dec 04, 2024
                      </div> */}
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="text-right">
                          <div className="text-sm font-semibold">
                            {transactionData?.deposit?.installments[0]?.value}
                          </div>
                          {/* <Badge className="mt-1 bg-blue-900 text-white hover:bg-blue-900">
                          received
                        </Badge> */}
                        </div>
                        {/* <Button variant="ghost" size="icon" className="h-6 w-6">
                    <ExternalLink className="h-4 w-4" />
                    <span className="sr-only">View deposit details</span>
                  </Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-zinc-500 text-base">No data found</div>
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Documents</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="mb-6">
              <h3 className="mb-4 text-sm font-medium">ID Documents</h3>
              <div className="grid gap-4 md:grid-cols-2">
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Primary ID</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Secondary ID</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <h3 className="mb-4 text-sm font-medium">Contract Documents</h3>
              <div className="grid gap-4 md:grid-cols-3">
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Purchase Agreement</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Disclosure Statement</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Addendums</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default TransactionOverviewTab
