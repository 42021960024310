/* eslint-disable */
import CustomButton from 'components/CustomButton'
import TableGrid from 'components/TableGrid/Table'
import useWorkflows, { useGetWorkflows } from 'hooks/useCustomWorkflows'
import useProjectId from 'hooks/useProjectId'
import useUser from 'hooks/useUser'
import { PlusCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { activateWorkflow, deactivateWorkflow } from 'store/actions/workflowActions'

export default function WorkflowManagement() {
    const { projectId } = useProjectId()
    const { user } = useUser()
    const [isCorporate] = useState(
        !!window.location.href.includes('corporate')
    )
    const { createWorkflow } = useWorkflows()
    const { workflows, isLoading: isLoadingList, refetch: refetchItems } = useGetWorkflows(
        {
            projectId, isCorporate
        }
    )
    const [setting, setSetting] = useState({})
    const history = useHistory()
    useEffect(() => {
        refetchItems()
    }, [refetchItems, projectId])

    if (isLoadingList) return <div>Loading...</div>

    function createNewAndRedirect() {
        const data = {
            projectId,
            isCorporate,
            createdBy: user._id
        }
        createWorkflow(data).then((response) => {
            const redirectUrl = `/admin/workflows/view?workflow=${response._id}`
            history.push(redirectUrl)
        })
    }

    return (
        <div className='flex flex-col gap-y-2 px-4 py-3'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-bold'>Automation Workflows</h1>
                <div>
                    <CustomButton color="primary" onClick={() => { createNewAndRedirect() }}
                        className='font-bold py-2 px-4'
                    >
                        <PlusCircle size={18} />  Create New Workflow
                    </CustomButton>
                </div>
            </div>
            <TableGrid
                pagination={true}
                style={{
                    height: '650px',
                    minHeight: '650px'
                }}
                columnDefs={[
                    {
                        headerName: '#',
                        field: '_id',
                        cellRenderer: (params) => {
                            return <div>View History</div>
                        },
                        onCellClicked: (params) => {
                            const url = `/admin/workflows/logs?workflow=${params.data._id}`
                            history.push(url)
                        }
                    },
                    {
                        headerName: 'Workflow Title',
                        field: 'name',
                        flex: 1,
                        onCellClicked: (params) => {
                            const url = `/admin/workflows/view?workflow=${params.data._id}`
                            history.push(url)
                        },
                    },

                    {
                        headerName: 'Actions',
                        field: 'draftFlow',
                        flex: 1,
                        cellRenderer: ({ value }) => {
                            return <ActionsView nodes={value.nodes} />
                        }
                    },
                    {
                        headerName: 'Status',
                        field: 'status',
                        sortable: true,
                        cellRenderer: ({ value }) => {
                            const status = String(value).toLowerCase()
                            return (<div>
                                {status === 'draft' && <span className='bg-gray-300 px-1.5 py-0.5 text-sm text-black rounded-full capitalize'>{status}</span>}
                                {status === 'active' && <span className='bg-green-500 px-1.5 py-0.5 text-sm text-white rounded-full capitalize'>{status}</span>}
                                {status === 'inactive' && <span className='bg-red-500 px-1.5 py-0.5 text-sm text-white rounded-full capitalize'>{status}</span>}
                            </div>)
                        }
                    },
                    {
                        headerName: 'Created On',
                        field: 'createdAt',
                        sortable: true,
                        cellRenderer: ({ value }) => {
                            return new Date(value).toLocaleString()
                        }
                    },
                    {
                        headerName: 'Updated On',
                        field: 'updatedAt',
                        sortable: true,
                        cellRenderer: ({ value }) => {
                            return new Date(value).toLocaleString()
                        }
                    },
                    {
                        headerName: 'Activate/Deactivate',
                        field: 'status',
                        cellRenderer: (params) => {
                            const workflow = params.data
                            const status = String(workflow.status).toLowerCase()
                            if (status === 'active') {
                                return <div>
                                    <button className='bg-red-500 text-white px-2 py-1 text-sm rounded-sm'
                                        onClick={() => {
                                            console.log('deactivate')
                                            if (!confirm('Are you sure you want to deactivate this workflow?')) {
                                                return;
                                            }
                                            setSetting({
                                                [workflow._id]: {
                                                    ...setting,
                                                    deactivating: true
                                                }
                                            })
                                            deactivateWorkflow(workflow._id).then(() => {
                                                refetchItems()
                                            }).finally(() => {
                                                setSetting({
                                                    [workflow._id]: {
                                                        ...setting,
                                                        deactivating: false
                                                    }
                                                })
                                            })
                                        }}
                                    >
                                        Deactivate
                                    </button>
                                </div>
                            } else if (status === 'inactive') {
                                return <div>
                                    <button className='bg-green-500 text-white px-2 py-1 text-sm rounded-sm'
                                        onClick={() => {
                                            console.log('activate')
                                            if (!confirm('Are you sure you want to activate this workflow?')) {
                                                return;
                                            }
                                            setSetting({
                                                [workflow._id]: {
                                                    ...setting,
                                                    activating: true
                                                }
                                            })
                                            activateWorkflow(workflow._id).then(() => {
                                                refetchItems()
                                            }).finally(() => {
                                                setSetting({
                                                    [workflow._id]: {
                                                        ...setting,
                                                        activating: false
                                                    }
                                                })
                                            })
                                        }}
                                    >
                                        Activate
                                    </button>
                                </div>
                            }
                            return <div></div>
                        }
                    },
                ]}
                getData={(filter, pagination, sorting) => {
                    return new Promise((resolve) => {
                        resolve(workflows)
                    })
                }}
            />
        </div>
    )
}


function ActionsView({ nodes }) {
    const list = nodes.map((node, index) => {
        const config = node?.data?.config || {}
        return {
            label: config.label,
            inputs: config.inputs,
            color: config.color,
            icon: config.icon
        }
    })
    return <div className='flex flex-row gap-x-1 overflow-visible'>
        {list.map((action, index) => {
            return <div key={index} className='relative items-center justify-center p-1 overflow-visible'
                style={{
                    border: `2px solid ${action.color}`,
                    borderRadius: '9999px',
                }}
            >
                <div className='absolute inset-0 rounded-full'
                    style={{
                        // add border and padding
                        border: `1px solid ${action.color}`,
                        opacity: 0.2,
                    }}
                />
                <img src={action.icon} alt={action.label} className='w-5 h-5' />
            </div>
        })}
    </div>
}