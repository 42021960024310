// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/tailwind.css";

const Select = React.forwardRef(
  (
    {
      className = "",
      options = [],
      placeholder = "Select an option",
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const selectRef = useRef(null);
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);
    useEffect(() => {
      const option = options.find((opt) => opt.value === value);
      setSelectedOption(option || null);
    }, [value, options]);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const selectOption = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
      if (onChange) {
        onChange(option.value);
      }
    };

    return (
      <div className="relative" ref={selectRef}>
        <button
          type="button"
          onClick={toggleDropdown}
          className={`flex h-9 w-full items-center justify-between rounded-md border border-zinc-200 bg-transparent px-3 py-2 text-sm placeholder:text-zinc-500 focus:outline-none focus:ring-1 focus:ring-zinc-950 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-800 dark:focus:ring-zinc-300 ${className}`}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          {...props}
        >
          <span className="flex-grow text-left truncate">
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`ml-2 h-4 w-4 shrink-0 transition duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        {isOpen && (
          <ul
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border border-zinc-200 bg-white py-1 text-base shadow-lg focus:outline-none dark:border-zinc-800 dark:bg-zinc-950 sm:text-sm"
            role="listbox"
          >
            {options.map((option) => (
              <li
                key={option.value}
                className={`relative cursor-default select-none py-2 pl-3 pr-9 text-zinc-900 hover:bg-zinc-100 dark:text-zinc-100 dark:hover:bg-zinc-800 `}
                role="option"
                onClick={() => selectOption(option)}
              >
                {selectedOption && selectedOption.value === option.value && (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-zinc-600 dark:text-zinc-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-4 w-4"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </span>
                )}
                <span className="block truncate pl-3">{option.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

Select.displayName = "Select";

export { Select };
