/* eslint-disable */

import TableGrid from 'components/TableGrid/Table'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { getWorkflowActions, getWorkflowLogs, } from 'store/actions/workflowActions'

export default function WorkflowsLogs() {
    const [loading, setLoading] = useState(true)
    const [workflowLogs, setWorkflowLogs] = useState([])
    const history = useHistory()
    const [availableActions, setAvailableActions] = useState([])
    const [loadingAvailableActions, setLoadingAvailableActions] = useState(true)

    useEffect(() => {
        getWorkflowActions().then((response) => {
            setAvailableActions(response)
        }).finally(() => {
            setLoadingAvailableActions(false)
        })
    }, [])

    useEffect(() => {
        const workflowId = new URLSearchParams(window.location.search).get('workflow')
        getWorkflowLogs(workflowId).then((response) => {
            setWorkflowLogs(response)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loadingAvailableActions || loading) {
        return <div>Loading...</div>
    }
    return (<div>
        <TableGrid
            pagination={true}
            style={{
                height: '650px',
                minHeight: '650px'
            }}
            columnDefs={[
                {
                    headerName: 'Workflow Title',
                    field: 'name',
                    flex: 1,
                    onCellClicked: (params) => {
                        console.log(params)
                        const workflowLog = params.data
                        const url = `/admin/workflows/actions/logs?workflow=${workflowLog.workflowId}&workflowInstance=${workflowLog._id}`
                        history.push(url)
                    },
                    cellRenderer: ({ value }) => {
                        return <div className='flex flex-row gap-x-2 items-center'>
                            <div className='flex flex-col gap-y-1'>
                                <div className='text-sm font-semibold text-blue-500'>{value}</div>
                            </div>
                        </div>
                    }
                },
                {
                    headerName: 'Actions',
                    field: 'actions',
                    flex: 1,
                    cellRenderer: ({ value }) => {
                        return <ActionsView actions={value} availableActions={availableActions} />
                    }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    sortable: true,
                    cellRenderer: (params) => {
                        const data = params.data
                        const actions = data.actions
                        let finalStatus = 'completed'
                        for (let i = actions.length - 1; i >= 0; i--) {
                            const action = actions[i];
                            if (action.status === 'failed') {
                                finalStatus = 'failed'
                                break;
                            } else if (action.status === 'pending') {
                                finalStatus = 'pending'
                                break;
                            }
                        }
                        return (<div>
                            <div className='flex flex-row gap-x-1 items-center'>
                                <div className='w-2 h-2 rounded-full'
                                    style={{
                                        backgroundColor: getColorForStatus(finalStatus)
                                    }}
                                />
                                <div>{finalStatus}</div>
                            </div>
                        </div>)
                    }
                },
                {
                    headerName: 'Created On',
                    field: 'createdAt',
                    sortable: true,
                    cellRenderer: ({ value }) => {
                        return new Date(value).toLocaleString()
                    }
                },
                {
                    headerName: 'Updated On',
                    field: 'updatedAt',
                    sortable: true,
                    cellRenderer: ({ value }) => {
                        return new Date(value).toLocaleString()
                    }
                },
            ]}
            getData={(filter, pagination, sorting) => {
                return new Promise((resolve) => {
                    const items = workflowLogs;
                    items.sort((a, b) => {
                        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                    })
                    resolve(items)
                })
            }}
        />
    </div>

    )
}

function getColorForStatus(status) {
    const _status = String(status).toLowerCase()
    if (_status === 'draft') {
        return 'gray'
    }
    if (_status === 'active') {
        return 'green'
    }
    if (_status === 'inactive') {
        return 'red'
    }
    if (_status === 'pending') {
        return 'orange'
    }
    if (_status === 'completed') {
        return 'green'
    }
    if (_status === 'failed') {
        return 'red'
    }
    return 'gray'
}

function ActionsView({ actions, availableActions }) {
    const list = actions.map((_action, index) => {
        const action = availableActions.find(action => action?.data?.config.id === _action.action)
        if (!action) {
            return null
        }
        const config = action?.data?.config
        const color = getColorForStatus(_action.status)
        return {
            label: config.label,
            status: _action.status,
            inputs: _action.inputs,
            color: color,
            icon: config.icon
        }
    })
    return <div className='flex flex-row gap-x-1 overflow-visible'>
        {list.map((action, index) => {
            return <div key={index} className='relative items-center justify-center p-1 overflow-visible'
                style={{
                    border: `2px solid ${action.color}`,
                    borderRadius: '9999px',
                }}
            >
                <div className='absolute inset-0 rounded-full'
                    style={{
                        // add border and padding
                        border: `1px solid ${action.color}`,
                        backgroundColor: action.color,
                        opacity: 0.2,
                    }}
                />
                <img src={action.icon} alt={action.label} className='w-5 h-5' />
            </div>
        })}
    </div>
}